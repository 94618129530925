import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import {
    type AggOpNames,
    CalculationType,
    OptimizationObjective,
    type PublicDimensionConfig,
    RenderDimension,
} from "../types";

const RAW_METRICS = {
    prebid_won_impressions: {},
    fair_available_impressions: {},
    prebid_won_revenue: {},
    fair_total_prebid_revenue: {},
    presence: {},
    entities_count: {},
    avg_present_in_days_frac: {},
} as const;
export type RawIndustryMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["ind_fair_share_win_rate", "ind_fair_share_revenue", "ind_presence"] as const;
export type IndustryWideDataMetricsType = (typeof CALC_IDS)[number];

const industryCorrectionForIncompletePresence = (
    m: Record<"ind_fair_share_win_rate" | "ind_fair_share_revenue" | "ind_presence" | RawIndustryMetrics, number>,
    opname: AggOpNames
) => {
    if (opname === "groupTotal") {
        return m.avg_present_in_days_frac;
    }
    return 1;
};

const DIMENSIONS = {
    industry_report_customer_type: {
        label: "Customer Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    industry_report_device: {
        label: "Device",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    industry_report_bidder: {
        label: "Winning Bidder",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    industry_report_country: {
        label: "Country",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    industry_report_size: {
        label: "Size",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    industry_report_browser: {
        label: "Browser",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    industry_report_referrerType: {
        label: "Referrer Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    industry_report_mediaType: {
        label: "Media Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type IndustryDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.date)
    .withTimeRanges(["Last 30 Days", "Last Quarter", "Last Year"])
    .withTimeGranularities(["by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        // end da server prediction
        // for industry panel
        ind_fair_share_win_rate: {
            label: "Normalized Win Rate",
            explanation: "Win Rate normalized by the amount of competition it had.",
            formula: (m, opname) =>
                (m.prebid_won_impressions / m.fair_available_impressions) *
                industryCorrectionForIncompletePresence(m, opname),
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        ind_fair_share_revenue: {
            label: "Relative Revenue",
            explanation: "Revenue from this bidder relative to the total prebid revenue.",
            formula: (m, opname) =>
                (m.prebid_won_revenue / 1_000 / m.fair_total_prebid_revenue) *
                industryCorrectionForIncompletePresence(m, opname) *
                1000 *
                1000 *
                1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        ind_presence: {
            label: "Presence",
            explanation: "Average number of sites working with this category",
            formula: (m, opname) =>
                (m.presence / m.entities_count) * industryCorrectionForIncompletePresence(m, opname),
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
    })
    .withMetricTable({
        initialMetrics: ["ind_presence", "ind_fair_share_win_rate", "ind_fair_share_revenue"],
        setup: {
            columnNames: {},
            tabMeta: {},
            tableMapping: {},
        },
    })
    .withDimensions(DIMENSIONS);
