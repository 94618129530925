import { type PublicDimensionConfig, RenderDimension } from "../types";
import { BUY_CAMPAIGN_DIMENSIONS } from "./buy_campaign";
import buyBase from "./shared/buy";
export const BUY_ADSET_DIMENSIONS = {
    ...BUY_CAMPAIGN_DIMENSIONS,
    buy_report_adset_name: {
        label: "Ad Group Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    buy_report_adset_id: {
        label: "Ad Group ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type BuydAdsetDimensionsIds = keyof typeof BUY_ADSET_DIMENSIONS;

export default buyBase.withDimensions(BUY_ADSET_DIMENSIONS);
