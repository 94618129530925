// @ts-check
import { STATUSPAGE_SUMMARY_URL } from "ay-core/src/constants/_constants";
import type { FC, ReactNode } from "react";
// @ts-ignore
import React, { createContext, useContext, useEffect } from "react";
import useSWR from "swr";

const fetcher = (url: string) => window.fetch(url).then(r => r.json());

type IImpact = "major" | "minor" | "critical" | "none";

// there's more to it => https://assertive.statuspage.io/api/
// the types are only for what we will use
interface IScheduledMantenance {
    name: string;
    status: "scheduled" | "in_progress"; // no other status is returned by the summary endpoint
    scheduled_for: string;
    scheduled_until: string;
    impact: IImpact;
    id: string;
}
const INITIAL_VALUES = {
    isHavingOutage: undefined as boolean | undefined,
    indicator: undefined as IImpact | undefined,
    description: undefined as string | undefined,
    scheduledMaintenances: undefined as IScheduledMantenance[] | undefined,
};

const LONG_POLL_STATUS_MS = 5000;

const getDescription = (summary: any): string => {
    const incidents = summary?.incidents ?? [];

    const description = summary?.status?.description;

    // if there are incidents, add them to the description
    if (incidents.length > 0) {
        const titles = incidents.map((it: { name?: string }) => it?.name);

        const incs = titles.join(", ");
        return `${description} - (${incs})`;
    }

    return description;
};

const THIRD_PARTY_GROUP_ID = "vqrt4mbyxpy0";
export const processSummary = (summary: any): typeof INITIAL_VALUES => {
    const indicator = summary?.status?.indicator;
    const description = getDescription(summary);
    const isHavingOutage = indicator != null && indicator !== "none";
    const scheduledMaintenances = summary?.scheduled_maintenances;

    const thirdPartyIds = summary?.components?.find((it: any) => it.id === THIRD_PARTY_GROUP_ID)?.components ?? [];

    const nonThirdPartyComponents =
        summary?.components?.filter((it: any) => !thirdPartyIds.includes(it.id) && it.id !== THIRD_PARTY_GROUP_ID) ??
        [];

    const isNonThirdPartyBroken = nonThirdPartyComponents.some((it: any) => it.status !== "operational");

    const thirdPartyComponents = summary?.components?.filter((it: any) => thirdPartyIds.includes(it.id)) ?? [];

    const isThirdPartyOnlyMinor =
        thirdPartyComponents.length > 0 &&
        thirdPartyComponents.every((it: any) => ["degraded_performance", "operational"].includes(it.status));

    if (!isNonThirdPartyBroken && isThirdPartyOnlyMinor) {
        return {
            isHavingOutage: false,
            indicator: "none",
            description: undefined,
            scheduledMaintenances,
        };
    }

    return {
        isHavingOutage,
        indicator,
        description,
        scheduledMaintenances,
    };
};

const useStatuspageHook = (isDevMode: boolean) => {
    const { data: summary, mutate } = useSWR(
        // We force an "ok" response in devMode
        // The API is swr's conditional fetching: https://swr.vercel.app/docs/conditional-fetching
        isDevMode ? null : STATUSPAGE_SUMMARY_URL,
        fetcher
    );

    useEffect(() => {
        const interval = setInterval(() => {
            mutate();
        }, LONG_POLL_STATUS_MS);

        return () => {
            clearInterval(interval);
        };
    }, [mutate]);
    return processSummary(summary);
};

// exported just for testing
export const StatuspageContext = createContext(INITIAL_VALUES);
export const StatuspageProvider: FC<{ isDevMode: boolean; children: ReactNode }> = ({ children, isDevMode }) => {
    const hook = useStatuspageHook(isDevMode);

    const getValue = () => {
        if (isDevMode) {
            return {
                isHavingOutage: false,
                indicator: "none" as IImpact,
                description: "All Systems Operational",
                scheduledMaintenances: [],
            };
        }

        return hook;
    };
    // @ts-ignore
    return <StatuspageContext.Provider value={getValue()}>{children}</StatuspageContext.Provider>;
};

export const useStatuspageStatus = () => useContext(StatuspageContext);
