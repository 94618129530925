export const ALL_PLACEMENTS = [
    "buy_campaign",
    "buy_adset",
    "buy_ad",
    "industry",
    "ad_revenue",
    "session_revenue",
    "session_time",
    "site",
    "spent_by_section",
    "request_reduction_client_v2",
    "request_reduction_server_latency",
    "request_reduction_server_samples_predict",
    "request_reduction_server_samples_profile",
    "traffic_shaping_server_flooring",
    "discrepancy_revenue",
    "financial",
    "view_dispersion",
    "error_log",
    "web",
    "error_analytics",
    "flooring",
    "segments",
    "yield",
    "prebid",
] as const;

export type ReportPlacementKeyType = (typeof ALL_PLACEMENTS)[number];

export const includesPlacements = (placements: ReportPlacementKeyType[], placement: ReportPlacementKeyType) =>
    placements.includes(placement);

export const OrganizationBasedPlacements: ReportPlacementKeyType[] = [
    "request_reduction_server_latency",
    "request_reduction_server_samples_predict",
    "request_reduction_server_samples_profile",
    "traffic_shaping_server_flooring",
];

export const OrganizationBasedPlacementsRequireEntities: ReportPlacementKeyType[] = [
    "discrepancy_revenue",
    "financial",
];
