import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    actual_revenue: {},
    actual_impressions: {},
    actual_revenue_ssp_currency: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type FinancialReportRawMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["actual_revenue", "actual_impressions", "actual_revenue_ssp_currency"] as const;
export type FinancialReportMetrics = (typeof CALC_IDS)[number];

const DIMENSIONS = {
    financial_bidder: {
        label: "SSP Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    financial_placement_id: {
        label: "Placement",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    financial_currency: {
        label: "SSP Currency",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    financial_timezone: {
        label: "Timezone",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type FinancialReportDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.date)
    .withTimeRanges(["Last 7 Days", "Last 30 Days", "This Month", "Last Month"])
    .withTimeGranularities(["by_day", "by_week"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        actual_revenue: {
            label: "Actual Revenue (USD)",
            explanation: "Actual revenue represented from SSP's connections in USD",
            formula: m => m.actual_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_impressions: {
            label: "Actual Impressions",
            explanation: "Actual impressions represented from SSP's connections",
            formula: m => m.actual_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_revenue_ssp_currency: {
            label: "Actual Revenue (SSP Currency)",
            explanation: "Actual revenue represented from SSP's connections in SSP currency",
            formula: m => m.actual_revenue_ssp_currency,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
    })
    .withMetricTable({
        initialMetrics: ["actual_impressions", "actual_revenue", "actual_revenue_ssp_currency"],
        setup: {
            tabMeta: {
                overall: { name: "Overall", explanation: "SSP connections financial metrics" },
            },
            columnNames: {
                revenue: "Revenue (USD)",
                impressions: "Impressions",
                revenueSSPCurrency: "Revenue (SSP Currency)",
            },
            tableMapping: {
                overall: {
                    revenue: ["actual_revenue"],
                    impressions: ["actual_impressions"],
                    revenueSSPCurrency: ["actual_revenue_ssp_currency"],
                },
            },
        },
    })
    .withDimensions(DIMENSIONS);
