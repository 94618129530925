import pre from "./yield.calculated-metrics";

export default pre.withMetricTable({
    initialMetrics: [
        "impressions",
        "session_starts",
        "revenue",
        "rpm",
        "session_rpm",
        "viewability",
        "sivt_rate",
        "vitals_cls_good_rate",
    ],
    setup: {
        tabMeta: {
            overall: {
                name: "Overall",
                explanation: "Prebid Won + Dynamic Allocation + Direct + Content Rec. + AdSense + Amazon",
            },
            prebid_won: { name: "Prebid Won", explanation: "Impressions prebid won over other demand." },
            dynamicAllocation: {
                name: "Dynamic Allocation",
                explanation: "Impressions won by Dynamic Allocation.",
            },
            amazon: { name: "Amazon", explanation: "Amazon Publisher Services demand" },
            direct: { name: "Direct", explanation: "Direct demand (winning non prebid line items)" },
            ad_sense: { name: "AdSense", explanation: "AdSense demand" },
            ay_mediation: { name: "AY Mediation", explanation: "AY Mediation demand" },
            content_recommendation: { name: "Content Rec.", explanation: "Content Recommendation demand" },
            ivt_and_vitals: { name: "IVT & Vitals", explanation: "Invalid Traffic & Web Vitals" },
            miss_clicks: { name: "Miss-Click", explanation: "Miss-clicks metrics" },
            prebid: { name: "Prebid", explanation: "Prebid Won + Prebid Lost" },
            prebid_lost: {
                name: "Prebid Lost",
                explanation: "Impressions prebid lost to other demand.",
            },
            programmatic: {
                name: "Programmatic",
                explanation: "Prebid Won + Dynamic Allocation + Content Rec. + AdSense + Amazon",
            },
            other: {
                name: "Other",
                explanation: "Single metrics that are not worth their own category.",
            },
            nativo: { name: "Nativo", explanation: "Nativo demand" },
        },
        columnNames: {
            general: "General",
            impression: "Impression",
            pageView: "Page View",
            session: "Session",
            miscellaneous: "Miscellaneous",
            da_predicted_client: "DA Predicted (Client)",
            da_predicted_server: "DA Predicted (Server)",
            invalid_traffic: "Invalid Traffic",
            web_vitals_avg: "Web Vitals Avg.",
            web_vitals_good: "Web Vitals Good Rate",
            combined: "Combined",
            spent: "Spent",
            other: "Other",
            // these are used by the site and spent reports, typescript it complaining otherwise
            yield: "Yield",
        },
        tableMapping: {
            overall: {
                general: ["revenue", "revenue_with_forecast", "clicks", "viewability", "ctr", "clr"],
                impression: [
                    "impressions",
                    "rpm",
                    "rpm_with_forecast",
                    "valueCpm",
                    "impressions_unfilled",
                    "impressions_unfilled_rate",
                    "impressions_unfilled_n_house",
                    "impressions_unfilled_n_house_rate",
                    "impressions_filled",
                    "impressions_filled_rate",
                    "blocked_impressions",
                    "blocked_impressions_rate",
                ],
                pageView: ["pageViews", "ads_per_pageView", "pageView_rpm", "pageView_valueRpm", "pageView_starts"],
                session: [
                    "sessions",
                    "session_starts",
                    "ads_per_session",
                    "session_rpm",
                    "session_start_rpm",
                    "session_start_rpm_with_forecast",
                    "session_rpm_with_forecast",
                    "session_valueRpm",
                    "pageViews_per_session",
                    "acquisition_cost",
                    "acquisition_cost_measurable_session_starts",
                ],
            },

            prebid_won: {
                general: ["prebid_winRate", "prebid_won_impressions", "prebid_won_revenue"],
                impression: ["prebid_won_rpm", "prebid_won_valueRpm"],
                pageView: ["prebid_won_pageView_rpm", "prebid_won_pageView_valueRpm"],
                session: ["prebid_won_session_rpm", "prebid_won_session_valueRpm"],
            },
            dynamicAllocation: {
                general: [
                    "dynamicAllocation_impressions",
                    "dynamicAllocation_revenue",
                    "dynamicAllocation_revenue_with_forecast",
                    "dynamicAllocation_winRate",
                ],
                impression: [
                    "dynamicAllocation_rpm",
                    "dynamicAllocation_valueRpm",
                    "dynamicAllocation_confirmed_clicks_rate",
                ],
                pageView: ["dynamicAllocation_pageView_rpm", "dynamicAllocation_pageView_valueRpm"],
                session: [
                    "dynamicAllocation_session_rpm",
                    "dynamicAllocation_session_rpm_with_forecast",
                    "dynamicAllocation_session_valueRpm",
                ],
            },
            amazon: {
                general: ["aps_impressions", "aps_revenue", "aps_winRate"],
                impression: ["aps_rpm"],
                pageView: ["aps_pageView_rpm"],
                session: ["aps_session_rpm"],
            },
            direct: {
                general: ["direct_impressions", "direct_revenue", "direct_winRate"],
                impression: ["direct_rpm", "direct_valueRpm"],
                pageView: ["direct_pageView_rpm", "direct_pageView_valueRpm"],
                session: ["direct_session_rpm", "direct_session_valueRpm"],
            },
            ad_sense: {
                general: ["adSense_impressions", "adSense_revenue", "adSense_winRate"],
                impression: ["adSense_rpm"],
                pageView: ["adSense_pageView_rpm"],
                session: ["adSense_session_rpm"],
            },
            ay_mediation: {
                general: ["ayMediation_impressions", "ayMediation_revenue", "ayMediation_winRate"],
                impression: ["ayMediation_rpm"],
                pageView: ["ayMediation_pageView_rpm"],
                session: ["ayMediation_session_rpm"],
            },
            content_recommendation: {
                general: [
                    "content_recommendation_impressions",
                    "content_recommendation_revenue",
                    "content_recommendation_winRate",
                ],
                impression: ["content_recommendation_rpm"],
                pageView: ["content_recommendation_pageView_rpm"],
                session: ["content_recommendation_session_rpm"],
            },
            ivt_and_vitals: {
                invalid_traffic: ["ivt_rate", "givt_rate", "sivt_rate"],
                web_vitals_avg: ["vitals_cls", "vitals_lcp", "vitals_inp", "vitals_fid", "vitals_ttfb", "vitals_fcp"],
                web_vitals_good: [
                    "vitals_cls_good_rate",
                    "vitals_lcp_good_rate",
                    "vitals_inp_good_rate",
                    "vitals_fid_good_rate",
                    "vitals_ttfb_good_rate",
                    "vitals_fcp_good_rate",
                ],
            },
            miss_clicks: {
                general: ["cbr", "clicks_bounce_session_rate"],
                impression: ["miss_click_impression_pct"],
                session: ["sessions_with_bounced_clicks"],
            },
            prebid: {
                general: [
                    "prebid_winRate",
                    "prebid_revenue",
                    "prebid_incremental_value",
                    "prebid_user_id_providers_avg",
                    "prebid_user_id_providers_zero_usages",
                    "prebid_user_id_providers_zero_usages_rate",
                ],
                impression: ["prebid_rpm", "prebid_valueRpm", "prebid_incremental_value_rpm", "prebid_cache_rate"],
                pageView: ["prebid_pageView_rpm", "prebid_pageView_valueRpm"],
                session: ["prebid_session_rpm", "prebid_session_valueRpm"],
            },
            prebid_lost: {
                general: ["prebid_lost_impressions", "prebid_lost_revenue"],
                impression: ["prebid_lost_rpm", "prebid_lost_valueRpm"],
                pageView: ["prebid_lost_pageView_rpm", "prebid_lost_pageView_valueRpm"],
                session: ["prebid_lost_session_rpm", "prebid_lost_session_valueRpm"],
            },
            programmatic: {
                general: [
                    "programmatic_impressions",
                    "programmatic_revenue",
                    "programmatic_revenue_with_forecast",
                    "programmatic_winRate",
                ],
                impression: ["programmatic_rpm", "programmatic_valueRpm"],
                pageView: ["programmatic_pageView_rpm", "programmatic_pageView_valueRpm"],
                session: [
                    "programmatic_session_rpm",
                    "programmatic_session_rpm_with_forecast",
                    "programmatic_session_valueRpm",
                ],
            },
            nativo: {
                general: ["nativo_impressions", "nativo_revenue", "nativo_winRate"],
                impression: ["nativo_rpm"],
                pageView: ["nativo_pageView_rpm"],
                session: ["nativo_session_rpm"],
            },
            other: {
                miscellaneous: [
                    "dynamicAllocation_revenue_with_forecast_client",
                    "lineItem_revenue",
                    "uniqueExternalId",
                    "dynamicAllocation_predicted_impressions",
                    "dynamicAllocation_predicted_impressions_error",
                    "floor_rpm",
                    "floor_impressions",
                    "floor_empirical_revenue_ratio",
                ],
                da_predicted_client: [
                    "dynamicAllocation_predicted_revenue",
                    "dynamicAllocation_predicted_rpm",
                    "dynamicAllocation_predicted_revenue_error",
                    "dynamicAllocation_predicted_revenue_r2",
                    "dynamicAllocation_predicted_revenue_mae",
                    "dynamicAllocation_predicted_revenue_rmse",
                    "dynamicAllocation_predicted_revenue_relative_error",
                ],
                da_predicted_server: [
                    "dynamicAllocation_predicted_revenue_server",
                    "dynamicAllocation_predicted_rpm_server",
                    "dynamicAllocation_predicted_revenue_error_server",
                    "dynamicAllocation_predicted_revenue_r2_server",
                    "dynamicAllocation_predicted_revenue_mae_server",
                    "dynamicAllocation_predicted_revenue_rmse_server",
                    "dynamicAllocation_predicted_revenue_relative_error_server",
                ],
            },
        },
    },
});
