import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    requests: {},
    waste: {},
    bids: {},
    wins: {},
    saved_waste: {},
    untouched_bids: {},
    lost_bids: {},
    lost_wins: {},
    lost_bid_volume: {},
    bid_volume: {},
    lost_won_volume: {},
    won_volume: {},
    untouched_bid_volume: {},
    left_waste: {},
    auctions: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawRequestReductionClientV2Metrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "request_reduction_client_requests",
    "request_reduction_client_waste_ratio",
    "request_reduction_client_saved_waste_ratio",
    "request_reduction_client_lost_bids_ratio",
    "request_reduction_client_lost_wins_ratio",
    "request_reduction_client_bid_volume_loss_ratio",
    "request_reduction_client_won_volume_loss_ratio",
    "request_reduction_client_untouched_bid_volume",
    "request_reduction_client_bid_volume_total",
    "request_reduction_client_bid_cpm",
    "request_reduction_client_bids",
    "request_reduction_client_wins",
    "request_reduction_client_saved_waste",
    "request_reduction_client_untouched_bids",
    "request_reduction_client_lost_bids",
    "request_reduction_client_lost_wins",
    "request_reduction_client_left_waste",
] as const;
export type RequestReductionClientMetricsType = (typeof CALC_IDS)[number];

const DIMENSIONS = {
    request_reduction_client_v2_country: {
        label: "Country",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_client_v2_bidder: {
        label: "Bidder",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_client_v2_host: {
        label: "Domain",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_client_v2_device_name: {
        label: "Device Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_client_v2_os_name: {
        label: "OS Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_client_v2_reduction_group: {
        label: "Reduction Group",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type RequestReductionClientDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities(["by_minute", "by_10_minutes", "by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        // auctions
        request_reduction_client_requests: {
            label: "Bid Requests",
            explanation: "Requests for a bid",
            formula: m => m.requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        request_reduction_client_waste_ratio: {
            label: "Waste",
            explanation: "Ratio of no-bids / bid requests",
            formula: m => m.waste / m.requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_bids: {
            label: "Bids",
            explanation: "Bids made by bidders",
            formula: m => m.bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_wins: {
            label: "Wins",
            explanation: "Wins made by bidders",
            formula: m => m.wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_saved_waste: {
            label: "Saved Waste",
            explanation: "Requests there weren't bids that would be filtered",
            formula: m => m.saved_waste,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_saved_waste_ratio: {
            label: "Waste Reduction",
            explanation: "Ratio of saved waste / total waste",
            formula: m => m.saved_waste / m.waste,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_untouched_bids: {
            label: "Untouched Bids",
            explanation: "Bids that wouldn't be filtered",
            formula: m => m.untouched_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_lost_bids: {
            label: "Lost Bids",
            explanation: "Bids that would be filtered",
            formula: m => m.lost_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_lost_wins: {
            label: "Lost Wins",
            explanation: "Wins that would be filtered",
            formula: m => m.lost_wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_lost_bids_ratio: {
            label: "Lost Bids",
            explanation: "Ratio of lost bids / bids",
            formula: m => m.lost_bids / m.bids,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_lost_wins_ratio: {
            label: "Lost Wins Ratio",
            explanation: "Ratio of lost wins / wins",
            formula: m => m.lost_wins / m.wins,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_bid_volume_loss_ratio: {
            label: "Lost Bid Volume",
            explanation: "Ratio of lost bid volume (sum of lost bids) / total bid volume (sum of bids)",
            formula: m => m.lost_bid_volume / m.bid_volume,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_won_volume_loss_ratio: {
            label: "Lost Won Volume Ratio",
            explanation: "Ratio of lost won volume (sum of winning bids) / total won volume (sum of bids)",
            formula: m => m.lost_won_volume / m.won_volume,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_untouched_bid_volume: {
            label: "Untouched Bid Volume",
            explanation: "Bid Volume (sum of bids) that was left untouched",
            formula: m => m.untouched_bid_volume / 1_000_000_000,
            calculationType: CalculationType.Sum,
            decimalPlaces: 2,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_bid_volume_total: {
            label: "Total Bid Volume",
            explanation: "Total Bid Volume (sum of bids)",
            formula: m => m.bid_volume / 1_000_000_000,
            calculationType: CalculationType.Sum,
            decimalPlaces: 2,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_bid_cpm: {
            label: "Bid CPM",
            explanation: "Bid Volume (sum of bids) / Number of Bids * 1000",
            formula: m => m.bid_volume / m.bids / 1_000_000,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_left_waste: {
            label: "Left Waste",
            explanation: "Requests there weren't bids that wouldn't be filtered",
            formula: m => m.left_waste,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
    })
    .withMetricTable({
        initialMetrics: [
            "request_reduction_client_requests",
            "request_reduction_client_waste_ratio",
            "request_reduction_client_saved_waste_ratio",
            "request_reduction_client_lost_bids_ratio",
            "request_reduction_client_lost_wins_ratio",
        ],
        setup: {
            tabMeta: {
                overall: { name: "Overall", explanation: "All metrics" },
            },
            columnNames: {
                ratio: "Ratios",
                absolute: "Absolutes",
            },
            tableMapping: {
                overall: {
                    ratio: [
                        "request_reduction_client_waste_ratio",
                        "request_reduction_client_saved_waste_ratio",
                        "request_reduction_client_lost_bids_ratio",
                        "request_reduction_client_lost_wins_ratio",
                        "request_reduction_client_bid_volume_loss_ratio",
                        "request_reduction_client_won_volume_loss_ratio",
                        "request_reduction_client_bid_cpm",
                    ],
                    absolute: [
                        "request_reduction_client_requests",
                        "request_reduction_client_bids",
                        "request_reduction_client_wins",
                        "request_reduction_client_saved_waste",
                        "request_reduction_client_untouched_bids",
                        "request_reduction_client_lost_bids",
                        "request_reduction_client_lost_wins",
                        "request_reduction_client_left_waste",
                        "request_reduction_client_untouched_bid_volume",
                        "request_reduction_client_bid_volume_total",
                    ],
                },
            },
        },
    })
    .withDimensions(DIMENSIONS);
