// extracted by mini-css-extract-plugin
export var content = "styles-module--content--3ab18";
export var description = "styles-module--description--5d058";
export var hitsContainer = "styles-module--hitsContainer--34334";
export var hitsWrapper = "styles-module--hitsWrapper--e9afa";
export var image = "styles-module--image--e5f32";
export var noItemsFound = "styles-module--noItemsFound--7637e";
export var resultsWrapper = "styles-module--resultsWrapper--f98e8";
export var search = "styles-module--search--5c9d9";
export var searchWrapper = "styles-module--searchWrapper--2f8d7";
export var title = "styles-module--title--7cb8c";