import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import yield_ from "./yield";

const yieldConfig = yield_.metricTable;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([])
    .withRawMetrics(yield_.rawMetrics)
    .withCalculatedMetricsIds(yield_.calculatedMetricsIds)
    .withCalculatedMetrics(yield_.metrics)
    .withMetricTable({
        initialMetrics: yieldConfig.initialMetrics,
        setup: {
            ...yieldConfig.setup,
        },
    })
    .withDimensions(yield_.dimensionsConfig);
