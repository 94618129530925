// @ts-ignore
import React, { type FC, useRef, useState } from "react";

export const SubscribeInput: FC<{
    email: string;
    setEmail: (it: string) => void;
    isValid: boolean;
}> = ({ email, setEmail, isValid }) => {
    return (
        <div className="subscribeToUpdates">
            <input
                className={["subscribe_input", isValid ? "" : "invalid"].join(" ")}
                placeholder="Enter your email"
                value={email}
                onChange={ev => setEmail(ev.target.value)}
            />
            {isValid ? <></> : <span className="invalid_text">Please provide a valid email</span>}
        </div>
    );
};

const FORM_ID = "0433dcd1-a8c4-4caf-ae83-1e5934aaaabf";
const PORTAL_ID = "26553047";

const isEmailValid = (email: string) => {
    // https://stackoverflow.com/a/46181
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};
const getCookie = (cookiename: string) => {
    // Get name followed by anything except a semicolon
    const cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
};

const doPostRequest = (email: string) => {
    const hutk = getCookie("hubspotutk");
    const pageUri = String(document.location);
    const pageName = String(document.title);

    const body = {
        fields: [
            {
                objectTypeId: "0-1",
                name: "email",
                value: email,
            },
        ],
        context: {
            hutk,
            pageUri,
            pageName,
        },
    };

    window
        .fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(it => it.text())
        // eslint-disable-next-line no-console
        .then(console.log)
        // eslint-disable-next-line no-console
        .catch(console.error);
};

export const useEmailSubscribe = () => {
    const [email, _setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const touchedSubmit = useRef(false);
    const [hasSubscribed, setHasSubscribed] = useState(false);

    const onSubscribe = () => {
        touchedSubmit.current = true;

        if (isEmailValid(email)) {
            doPostRequest(email);
            setHasSubscribed(true);
        } else {
            setIsValid(false);
        }
    };

    const setEmail = (email: string) => {
        _setEmail(email);

        if (touchedSubmit.current) {
            setIsValid(isEmailValid(email));
        }
    };

    return {
        email,
        isValid,
        setEmail,
        onSubscribe,
        hasSubscribed,
    };
};
