import { REVENUE_SOURCE_OPTIONS } from "../../shared";
import { type PublicDimensionConfig, RenderDimension } from "../../types";
import { YieldPrebidDimensions } from "../shared/yield-prebid-dimensions";
import { YieldPrebidWebDimensions } from "../shared/yield-prebid-web-dimensions";
import { YieldWebDimensions } from "../shared/yield-web-dimensions";
import pre from "./yield.metric-table";

const DIMENSIONS = {
    ...YieldPrebidWebDimensions,
    ...YieldPrebidDimensions,
    ...YieldWebDimensions,
    acquisition_clickId: {
        label: "Acquisition ClickId",
        row_type: "String",
        render: RenderDimension.PlainString,
        isApiOnly: true,
        uiGroup: "none",
    },
    acquisition_clickId_param: {
        label: "Acquisition ClickId Param",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        isApiOnly: true,
        isTombstone: true,
    },
    amazonBidderId: {
        label: "Amazon Bidder Raw",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    mapped_amazonBidderId: {
        label: "Amazon Bidder",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    deal: {
        label: "Deal ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    mapped_deal: {
        label: "Deal ID - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpAdvertiser: {
        label: "GAM Advertiser",
        description: "The GAM Advertiser.",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "ad_server",
    },
    mapped_dfpAdvertiser: {
        description: "The GAM Advertiser.",

        label: "GAM Advertiser - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpAdvertiserId: {
        label: "GAM Advertiser Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    mapped_dfpAdvertiserId: {
        description: "The GAM Advertiser Id.",

        label: "GAM Advertiser Id - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpHash: {
        label: "AY Hash K/V",
        row_type: "String",
        render: RenderDimension.PlainString,
        description: "The custom key/value used to sync GAM and AY.",
        uiGroup: "ad_server",
    },
    dfpHashRaw: {
        label: "Raw AY Hash K/V",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    dfpOrder: {
        description: "The GAM Order.",

        label: "GAM Order",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "ad_server",
    },
    mapped_dfpOrder: {
        description: "The GAM Order.",

        label: "GAM Order - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpOrderId: {
        label: "GAM Order Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    mapped_dfpOrderId: {
        description: "The GAM Order Id.",

        label: "GAM Order Id - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpLineItem: {
        description: "The GAM Line Item.",

        label: "GAM Line Item",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "ad_server",
    },
    mapped_dfpLineItem: {
        description: "The GAM Line Item.",

        label: "GAM Line Item - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpLineItemId: {
        label: "GAM Line Item Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    mapped_dfpLineItemId: {
        description: "The GAM Line Item Id.",

        label: "GAM Line Item Id - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpLineItemType: {
        description: "The GAM Line Item Type.",

        label: "GAM Line Item Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "ad_server",
    },
    dfpLineItemCostType: {
        label: "GAM Line Item Cost Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    dfpCreative: {
        description: "The GAM Creative.",
        label: "GAM Creative",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "ad_server",
    },
    mapped_dfpCreative: {
        description: "The GAM Creative.",

        label: "GAM Creative - Mapped",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    dfpIsBackfill: {
        label: "Is Dynamic Allocation",
        row_type: "Number",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "ad_server",
    },
    source: {
        hideFromApiDocs: true,
        label: "Revenue Source",

        row_type: "Enum",
        render: RenderDimension.PlainString,
        enumOptions: REVENUE_SOURCE_OPTIONS,
        uiGroup: "none",
    },

    placement: {
        label: "Placement",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    adUnit: {
        label: "Ad Unit",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    mapped_adUnit: {
        label: "Ad Unit - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    size: {
        label: "Size",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    adQualityIsBlocked: {
        label: "Ad Quality Is Blocked",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    adQualityBlockingType: {
        label: "Ad Quality Blocking Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    }, // Implementation
    prebidUserIdProviderUsed: {
        label: "PB UID Provider Provided",
        row_type: "Number",
        // We check if it is empty and force it not to be otherwise to avoid empty arrays "zeroing" the entire query.
        // Check !4783 or #2741 for more details.
        isMultiAttribution: true,
        uiGroup: "implementation",
        render: RenderDimension.PlainString,
    },
    prebidUserIdProviderConfigured: {
        label: "PB UID Provider",
        row_type: "String",
        // We check if it is empty and force it not to be otherwise to avoid empty arrays "zeroing" the entire query.
        // Check !4783 or #2741 for more details.
        isMultiAttribution: true,
        uiGroup: "implementation",
        render: RenderDimension.PlainString,
    },

    placementRenderCount: {
        label: "Placement Render Count",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    refreshCount: {
        label: "Ad Refresh Count",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    externalId: {
        label: "External Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    is_ad_unfilled: {
        label: "Is Unfilled",
        row_type: "Number",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type YieldDimensionIds = keyof typeof DIMENSIONS;

export default pre.withDimensions(DIMENSIONS);
