import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    sessions: {},
    errors: {},
    same_bid_error_count: {},
    fatal_prebid_error_count: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawErrorAnalyticsMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["errors", "sessions", "same_bid_error_count", "fatal_prebid_error_count"] as const;
export type ErrorAnalyticsMetrics = (typeof CALC_IDS)[number];

const DIMENSIONS = {
    error_analytics_meta_host: {
        label: "Domain",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    error_analytics_meta_source: {
        label: "Error Source",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    error_analytics_meta_version: {
        label: "Version (Error)",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    browser: {
        label: "Browser",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    country: {
        label: "Country",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    countryCode: {
        label: "Country Code",
        row_type: "FixedString(2)",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    operatingSystem: {
        label: "Operating System",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type ErrorAnalyticsDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([
        "by_minute",
        "by_10_minutes",
        "by_hour",
        "by_day",
        "by_week",
        "by_month",
        "by_quarter",
        "no_granularity",
    ])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        errors: {
            label: "Errors",
            explanation: "Sum of errors",
            formula: m => m.errors,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        sessions: {
            label: "Sessions",
            explanation: "Sum of sessions",
            formula: m => m.sessions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        same_bid_error_count: {
            label: "Same Bid Errors",
            explanation: "Errors related to same bid (bid re-used)",
            formula: m => m.same_bid_error_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        fatal_prebid_error_count: {
            label: "Fatal Prebid Errors",
            explanation: "Fatal prebid errors like failed ad renders",
            formula: m => m.fatal_prebid_error_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
    })
    .withMetricTable({
        initialMetrics: [],
        setup: {
            columnNames: {},
            tabMeta: {},
            tableMapping: {},
        },
    })
    .withDimensions(DIMENSIONS);
