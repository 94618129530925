import { FC } from "react";
import { Highlight, Hits, useHits, useInstantSearch } from "react-instantsearch";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";

type BlogHit = {
    hit: {
        title: string;
        description: string;
        link: string;
        image: string;
        relatedFeatures: string[];
    };
};

type EmptyQueryBoundary = {
    children: JSX.Element;
    fallback: JSX.Element | null;
};

const EmptyQueryBoundary: FC<EmptyQueryBoundary> = ({ children, fallback }) => {
    const { indexUiState } = useInstantSearch();

    if (!indexUiState.query) {
        return (
            <>
                {fallback}
                <div hidden>{children}</div>
            </>
        );
    }

    return children;
};

export const BlogHit: FC<BlogHit> = ({ hit }) => (
    <Link to={hit.link} className={styles.searchWrapper}>
        <img className={styles.image} src={hit.image} alt={hit.title} />
        <div className={styles.content}>
            <h3 className={styles.title}>
                <Highlight attribute="title" hit={hit as any} />
            </h3>
            <p className={styles.description}>
                <Highlight attribute="description" hit={hit as any} />
            </p>

            {hit.relatedFeatures && (
                <div>
                    {hit.relatedFeatures.map((feature, index) => (
                        <span key={index} className="me-1">
                            {feature}
                        </span>
                    ))}
                </div>
            )}
        </div>
    </Link>
);

export const CustomBlogHits = () => {
    const { hits } = useHits();

    return (
        <EmptyQueryBoundary fallback={null}>
            <div className={styles.hitsContainer}>
                {hits.length > 0 ? (
                    <Hits hitComponent={BlogHit} />
                ) : (
                    <div className={styles.noItemsFound}>No items were found.</div>
                )}
            </div>
        </EmptyQueryBoundary>
    );
};
