import { uniqBy } from "lodash";
import type { PublicUser } from "../codecs/user-data.codec";
import { ADMIN_OR_DEV_IDS, type CustomLabelsStored } from "../constants/_constants";
import { type DimensionKeys, RenderRowsCountByDimension } from "../constants/dimension-keys.constants";
import { LockDimensionsStates } from "../constants/lock-dimensions-states.constants";
import type { ReportPlacementKeyType } from "../constants/placement.constants";
import { STD } from "../utils/std";
import { PLACEMENTS_METRIC_CONFIG } from "./all";
import { type PublicDimensionConfig, RenderDimension, type SelectedDimension } from "./types";

// const dimensionsInternal = dimensions;

export namespace DimensionsService {
    const AlertDimensionsToRemove = [
        "dayOfMonth",
        "date",
        "dayOfWeek",
        "hourOfDay",
        "fullDate",
        "experiments_key_value",
        "experiments_key_value_raw",
    ];

    export const getAlertDimensions = (reportType: "prebid" | "yield" | "web" | "error_analytics" | null) => {
        if (reportType === null) {
            return [];
        }
        const dimensionsInternal = getDimensionsConfigByPlacement(reportType);
        return getDimensionIdsFromPlacement(reportType)
            .map(id => dimensionsInternal[id])
            .filter(
                d =>
                    !d.limitedToAdminOrDev &&
                    !AlertDimensionsToRemove.includes(d.id) &&
                    shouldShowInUi(reportType, d.id)
            )
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(m => ({ label: m.label, value: m.id }));
    };

    export const isDimensionKey = (placement: ReportPlacementKeyType, key: string): key is DimensionKeys => {
        const validIds = getDimensionIdsFromPlacement(placement);

        if (!validIds.includes(key as any)) {
            return false;
        }
        const dimensionsInternal = getDimensionsConfigByPlacement(placement);

        return Object.keys(dimensionsInternal).includes(key);
    };

    const getDimensionsConfigByPlacement = (
        placement: ReportPlacementKeyType
    ): Record<DimensionKeys, PublicDimensionConfig> => {
        return PLACEMENTS_METRIC_CONFIG[placement].dimensionsConfig as Record<DimensionKeys, PublicDimensionConfig>;
    };

    export const getDimensionIdsFromPlacement = STD.memoizer((placement: ReportPlacementKeyType): DimensionKeys[] => {
        return Object.keys(getDimensionsConfigByPlacement(placement)).sort((a, b) =>
            a.localeCompare(b)
        ) as DimensionKeys[];
    });

    const getDimensionConfigInput = (
        placement: ReportPlacementKeyType,
        dimensionId: DimensionKeys
    ): PublicDimensionConfig | undefined => {
        const dimensionsInternal = getDimensionsConfigByPlacement(placement);
        const isValid = isDimensionKey(placement, dimensionId);
        if (!isValid) {
            throw new Error(`Dimension ${dimensionId} is not valid for placement ${placement}`);
        }
        return dimensionsInternal[dimensionId];
    };
    export const getRenderType = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.render ?? RenderDimension.PlainString;
    };

    export const getUiGroup = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.uiGroup ?? "none";
    };

    export const getDimensionRowType = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.row_type ?? "String";
    };
    export const getShouldLimit = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.shouldLimit ?? false;
    };
    export const getDimensionIsLimitedToAdminOrDev = (
        placement: ReportPlacementKeyType,
        dimensionId: DimensionKeys
    ) => {
        return getDimensionConfigInput(placement, dimensionId)?.limitedToAdminOrDev ?? false;
    };
    export const getEnumOptions = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.enumOptions ?? [];
    };
    export const isMultiAttribution = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.isMultiAttribution ?? false;
    };
    const isTombstone = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.isTombstone ?? false;
    };

    const isApiOnly = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.isApiOnly ?? false;
    };

    export const shouldShowInUi = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        // not tombstone and not api only

        return !isTombstone(placement, dimensionId) && !isApiOnly(placement, dimensionId);
    };

    export const getDimensionDescription = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        return getDimensionConfigInput(placement, dimensionId)?.description;
    };
    export const getDimensionLabel = (
        placement: ReportPlacementKeyType,
        dimensionId: DimensionKeys,
        customLabels: CustomLabelsStored | null | undefined,
        ifNotCustomUse?: string | null | undefined // sometimes we use the report title instead of the dimension title
    ): string => {
        const label = ifNotCustomUse ?? getDimensionConfigInput(placement, dimensionId)?.label ?? `~${dimensionId}~`;

        if (customLabels == null) {
            return label;
        }

        const customReplacedLabel: string | undefined | null = addMappedToCustomLabels(customLabels)[dimensionId];

        if (customReplacedLabel != null) {
            return customReplacedLabel;
        }

        return label;
    };
    export const getPublicDimensionsTableData = (placement: "yield" | "web") => {
        const data = getDimensionIdsFromPlacement(placement)
            .filter(dimensionId => !getDimensionConfigInput(placement, dimensionId)?.isTombstone)
            .filter(dimensionId => !getDimensionConfigInput(placement, dimensionId)?.limitedToAdminOrDev)
            .filter(dimensionId => !getDimensionConfigInput(placement, dimensionId)?.hideFromApiDocs)
            .map(dimensionId => {
                return {
                    title: getDimensionConfigInput(placement, dimensionId)?.label ?? `~${dimensionId}~`,
                    description: getDimensionConfigInput(placement, dimensionId)?.description ?? "",
                    dimensionId,
                };
            })
            .sort((a, b) => String(a?.dimensionId).localeCompare(String(b?.dimensionId)));

        return data;
    };

    export const getMultiOptionsAvailable = (
        propsDimensions: DimensionKeys[],
        savedReportLockDimensions: LockDimensionsStates
    ) => {
        if (propsDimensions.length) {
            return savedReportLockDimensions || LockDimensionsStates.UNLOCKED;
        }
        return LockDimensionsStates.LOCKED;
    };

    const mappedIdFromDimensionId = (id: string): string => "mapped_" + id;
    const mappedLabelFromCustomLabel = (label: string): string => label + " - Mapped";
    const addMappedToCustomLabels = (
        customLabels: CustomLabelsStored | null | undefined
    ): Record<string, string | undefined | null> => {
        if (customLabels == null) {
            return {};
        }
        const tmp: Record<string, string | undefined | null> = {
            ...customLabels,
        };

        STD.getEntriesPartial(customLabels).forEach(([key, value]) => {
            tmp[mappedIdFromDimensionId(key)] = mappedLabelFromCustomLabel(value);
        });

        return tmp;
    };

    const checkIfReportDimensionIsLocked = (placement: ReportPlacementKeyType, dimensionId: DimensionKeys) => {
        if (placement === "buy_ad" || placement === "buy_adset" || placement === "buy_campaign") {
            return dimensionId === "buy_report_platform";
        }
        if (placement === "site") {
            return dimensionId === "site_report_platform";
        }
        if (placement === "session_revenue" || placement === "session_time") {
            return false;
        }
        return true;
    };

    export const getSavedReportDimensionsWithLocked = (
        savedReportDimensions: DimensionKeys[],
        savedReportLockDimensions: LockDimensionsStates,
        isEmbed: boolean
    ) =>
        savedReportDimensions.map(dimension => ({
            id: dimension,
            locked: isEmbed && savedReportLockDimensions === LockDimensionsStates.LOCKED,
        }));

    export const getReportDimensionsWithLocked = (
        reportDimensions: DimensionKeys[],
        placement: ReportPlacementKeyType
    ) =>
        reportDimensions.map(id => ({
            id,
            locked: checkIfReportDimensionIsLocked(placement, id),
        }));

    export const getViewDefaultDimensions = (
        reportDimensions: DimensionKeys[],
        savedReportDimensions: DimensionKeys[],
        savedReportLockDimensions: LockDimensionsStates,
        placement: ReportPlacementKeyType,
        isEmbed: boolean
    ): SelectedDimension[] => {
        const reportDimensionsWithLocked = getReportDimensionsWithLocked(reportDimensions, placement);
        const savedReportDimensionsWithLocked = getSavedReportDimensionsWithLocked(
            savedReportDimensions,
            savedReportLockDimensions,
            isEmbed
        );
        const dimensionsWithLocks = reportDimensionsWithLocked
            .concat(savedReportDimensionsWithLocked)
            .sort((a, b) => (a.locked === b.locked ? 0 : a.locked ? -1 : 1));
        return uniqBy(dimensionsWithLocks, "id");
    };

    const getRenderRowsCountByDimension = (dimension: DimensionKeys | undefined) => {
        switch (dimension) {
            case "hourOfDay":
                return RenderRowsCountByDimension.hourOfDay;
            case "dayOfWeek":
                return RenderRowsCountByDimension.dayOfWeek;
            case "dayOfMonth":
                return RenderRowsCountByDimension.dayOfMonth;
            default:
                return RenderRowsCountByDimension.dayOfWeek;
        }
    };

    export const getRenderedRows = (firstSelectedDimensioned?: SelectedDimension) => {
        const dimensionKey = firstSelectedDimensioned?.id;
        return Array.from(Array(getRenderRowsCountByDimension(dimensionKey)).keys());
    };

    export const getDimensionOptions = (
        placement: ReportPlacementKeyType,
        allowedDimensionIds: DimensionKeys[],
        customLabels: CustomLabelsStored | null | undefined,
        currentUser: PublicUser | undefined,
        supportsMultiAttribution: boolean
    ): { value: DimensionKeys; label: string }[] => {
        const isAdminOrDev = ADMIN_OR_DEV_IDS.includes(currentUser?._id ?? "");
        return allowedDimensionIds
            .filter(it => {
                const limitedToAdminOrDev = DimensionsService.getDimensionIsLimitedToAdminOrDev(placement, it);
                if (limitedToAdminOrDev) {
                    return isAdminOrDev;
                }
                return true;
            })
            .filter(it => {
                if (supportsMultiAttribution) {
                    return true;
                }

                const isMultiAttribution = DimensionsService.isMultiAttribution(placement, it);

                return !isMultiAttribution;
            })
            .map(value => ({
                value,
                label: getDimensionLabel(placement, value, customLabels),
            }));
    };

    export const getAvailableDimensions = (
        placement: ReportPlacementKeyType,
        lockDimensions: LockDimensionsStates,
        allowedDimensionIds: DimensionKeys[],
        customLabels: CustomLabelsStored | null | undefined,
        savedReportDimensions: DimensionKeys[],
        currentUser: PublicUser | undefined,
        supportsMultiAttribution: boolean
    ) => {
        const isOnlyDimensionRemoval = lockDimensions !== LockDimensionsStates.UNLOCKED;
        if (isOnlyDimensionRemoval) {
            return savedReportDimensions.map(id => ({
                locked: false,
                label: getDimensionLabel(placement, id, customLabels),
                value: id,
            }));
        }
        return getDimensionOptions(placement, allowedDimensionIds, customLabels, currentUser, supportsMultiAttribution);
    };

    export const getSelectedDimensions = (
        placement: ReportPlacementKeyType,
        currentDimensions: SelectedDimension[],
        customLabels: CustomLabelsStored | null | undefined
    ) =>
        currentDimensions.map(it => ({
            value: it.id,
            locked: it.locked,
            label: getDimensionLabel(placement, it.id, customLabels),
        }));
}
