import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicDimensionConfig } from "../types";
import prebid from "./prebid";
import web from "./web";
import yield_ from "./yield";

const DIMENSIONS = {
    ...yield_.dimensionsConfig,
    ...prebid.dimensionsConfig,
    ...web.dimensionsConfig,
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([])
    .withRawMetrics({})
    .withCalculatedMetricsIds([])
    .withCalculatedMetrics({})
    .withMetricTable({
        initialMetrics: [],
        setup: {
            columnNames: {},
            tabMeta: {},
            tableMapping: {},
        },
    })
    .withDimensions(DIMENSIONS);
