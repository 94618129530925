import type { FC, ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { SubscribeInput, useEmailSubscribe } from "ay-md-docs-render/src/SubscribeInput";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AdBannerWrapper from "components/adBannerWrapper";
import CustomButton from "components/CustomButton";
import { useCopyToClipboard } from "components/hooks/use-copy-to-clipboard";
import MarkdownRenderer from "components/MarkdownRenderer";
import FacebookIcon from "img/facebook-logo-grey.svg";
import LinkedinIcon from "img/linkedin-logo-grey.svg";
import TwitterIcon from "img/twitter-icon-grey.svg";
import type { AuthorsCollection, BlogPostData } from "types/common";
import type { TAds } from "types/common";
import * as styles from "./styles.module.scss";

const CopyLinkButton: FC<{
    onClick: () => void;
    wasCopied: boolean;
}> = ({ onClick, wasCopied }) => (
    <button
        className={[
            styles.white_rounded_button,
            wasCopied ? styles.copy_link_icon_before_was_copied : styles.copy_link_icon_before_default_state,
            styles.copy_link_icon_before,
        ].join(" ")}
        onClick={onClick}
    >
        Copy Link
    </button>
);

const SocialButton: FC<{ children: ReactNode; href: string }> = ({ children, href }) => (
    <a
        className={[styles.white_rounded_button, styles.cursor_pointer, "ms-2"].join(" ")}
        style={{ padding: 10 }}
        target="__blank"
        href={href}
    >
        {children}
    </a>
);

const getPostUrl = (post: BlogPostData, siteUrl: string) => `${siteUrl}/blog${post.fields.slug}`;

const shareToTwitter = (post: BlogPostData, siteUrl: string) => {
    const title = encodeURI(post.frontmatter.title);
    const url = encodeURI(getPostUrl(post, siteUrl));
    return `https://twitter.com/intent/tweet/?url=${url}&text=${title}`;
};
const shareToFacebook = (post: BlogPostData, siteUrl: string) => {
    const url = encodeURI(getPostUrl(post, siteUrl));
    return `https://facebook.com/sharer/sharer.php?u=${url}`;
};
const shareToLinkedin = (post: BlogPostData, siteUrl: string) => {
    const url = encodeURI(getPostUrl(post, siteUrl));
    const title = encodeURI(post.frontmatter.title);
    const description = encodeURI(post.frontmatter.description);
    return `https://www.linkedin.com/shareArticle?url=${url}&title=${title}&summary=${description}&source=${url}&mini=true`;
};

const BlogKV: FC<{ itemKey: string; value: string }> = ({ itemKey, value }) => {
    return (
        <div className={[styles.kv_item].join(" ")}>
            <div className={[styles.kv_key, "mb-2"].join(" ")}>{itemKey}</div>
            <div className={[styles.kv_value].join(" ")}>{value}</div>
        </div>
    );
};

const BlogPost: FC<{
    post: BlogPostData;
    image: ReactNode;
    ads: TAds;
    site?: {
        siteMetadata: {
            siteUrl: string;
        };
    };
    currentPerson?: AuthorsCollection;
}> = ({ post, image, ads, site, currentPerson }) => {
    const { wasCopied, onCopy } = useCopyToClipboard();

    const siteUrl = site?.siteMetadata.siteUrl ?? "/";
    const { email, setEmail, onSubscribe, isValid, hasSubscribed } = useEmailSubscribe();

    const { horizontalAdFileExternal, verticalAdFileExternal } = ads;

    const horizontalAd = getImage(horizontalAdFileExternal);
    const verticalAd = getImage(verticalAdFileExternal);

    const substituteRelatedFeaturesAccordingToTheTags = (tags: string[]) => {
        if (!tags) {
            return [];
        }

        const relatedFeatures: string[] = [];

        tags.forEach(tag => {
            if (tag === "Efficiency & Sustainability") {
                relatedFeatures.push("Traffic Shaping");
            } else if (tag === "Data Intelligence") {
                relatedFeatures.push("Yield Intelligence");
            } else if (tag === "Ad Revenue Management") {
                relatedFeatures.push("Yield Manager");
                relatedFeatures.push("AY Prebid Server");
            } else if (tag === "AI Optimization") {
                relatedFeatures.push("Yield Optimizer");
                relatedFeatures.push("Dynamic Flooring");
            } else if (
                tag !== "Efficiency & Sustainability" &&
                tag !== "Data Intelligence" &&
                tag !== "Ad Revenue Management" &&
                tag !== "AI Optimization" &&
                tag !== "Programmatic Trends"
            ) {
                relatedFeatures.push("General");
            }
        });

        return relatedFeatures;
    };

    const addFeatureButton = (feature: string) => {
        if (feature === "Yield Intelligence") {
            return (
                <CustomButton buttonSize="btn--medium" buttonStyle="btn--primary" to="/yield-intelligence">
                    {feature}
                </CustomButton>
            );
        } else if (feature === "Yield Manager") {
            return (
                <CustomButton buttonSize="btn--medium" buttonStyle="btn--yellow-red" to="/yield-manager">
                    {feature}
                </CustomButton>
            );
        } else if (feature === "Yield Optimizer" || feature === "Dynamic Flooring") {
            return (
                <CustomButton buttonSize="btn--medium" buttonStyle="btn--blue-pink" to="/yield-optimizer">
                    {feature}
                </CustomButton>
            );
        } else if (
            feature === "Traffic Shaping" ||
            feature === "Traffic Shaping for Pubs" ||
            feature === "Traffic Shaping for SSPs" ||
            feature === "AY Prebid Server"
        ) {
            return (
                <CustomButton buttonSize="btn--medium" buttonStyle="btn--blue-pink" to="/traffic-shaping">
                    {feature}
                </CustomButton>
            );
        } else if (feature === "AY Industry Insights") {
            return (
                <CustomButton buttonSize="btn--medium" buttonStyle="btn--blue-pink" to="/industry-insights">
                    {feature}
                </CustomButton>
            );
        } else {
            return (
                <CustomButton buttonSize="btn--medium" buttonStyle="btn--primary" to="/blog/all/">
                    {feature}
                </CustomButton>
            );
        }
    };

    const addLinkToTag = (tag: string) => {
        if (tag === "Efficiency & Sustainability") {
            return "/blog/tag/efficiency-&-sustainability-tag";
        } else if (tag === "Data Intelligence") {
            return "/blog/tag/data-intelligence-tag";
        } else if (tag === "Ad Revenue Management") {
            return "/blog/tag/ad-revenue-management-tag";
        } else if (tag === "AI Optimization") {
            return "/blog/tag/ai-optimization-tag";
        } else {
            return "";
        }
    };

    const substitutedRelatedFeaturesAccordingToTheTags = substituteRelatedFeaturesAccordingToTheTags(
        post.frontmatter.tags!
    );

    return (
        <>
            <div className="d-flex flex-row">
                <div className={[styles.minutes_read].join(" ")}>
                    <span className={styles.minutes_read_text}>
                        {Math.round(post.fields.readingTime!.minutes)} min read
                    </span>
                </div>
            </div>
            <header className={["mt-4"].join(" ")}>
                <h1 className={[styles.dark_text, styles.post_title, "figma-h2"].join(" ")}>
                    {post.frontmatter.title}
                </h1>
                <Row>
                    <Col xs={12} xl={8}>
                        <p className={[styles.description, "mt-4"].join(" ")}>{post.frontmatter.description}</p>
                    </Col>
                </Row>
            </header>
            {image}
            {!currentPerson ? (
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <div className={[styles.kvs_wrapper].join(" ")}>
                        <BlogKV itemKey={"Written By"} value={post.frontmatter.author} />
                    </div>
                </div>
            ) : null}
            <Row className="mt-5 mb-6">
                <Col xs={12} xl={8} className="pt-4 pb-4">
                    <MarkdownRenderer post={post} />
                    <div className={styles.shareWrapper}>
                        <CopyLinkButton wasCopied={wasCopied} onClick={() => onCopy(getPostUrl(post, siteUrl))} />
                        <div className="d-flex">
                            <SocialButton href={shareToTwitter(post, siteUrl)}>
                                <img src={TwitterIcon} width="20" height="20" alt="" />
                            </SocialButton>
                            <SocialButton href={shareToFacebook(post, siteUrl)}>
                                <img src={FacebookIcon} width="20" height="20" alt="" />
                            </SocialButton>
                            <SocialButton href={shareToLinkedin(post, siteUrl)}>
                                <img src={LinkedinIcon} width="20" height="20" alt="" />
                            </SocialButton>
                        </div>
                    </div>
                    <div className={styles.linksWrapper}>
                        {post.frontmatter.tags ? (
                            <div className={styles.tagsWrapper}>
                                <p className={styles.title}>Explore more about</p>
                                <div className={styles.tags}>
                                    {post.frontmatter.tags.map((tag: string, index: number) => (
                                        <div className={styles.tag} key={index}>
                                            <CustomButton
                                                buttonSize="btn--medium"
                                                buttonStyle="btn--yellow-red"
                                                to={addLinkToTag(tag) ?? "#"}
                                                key={index}
                                            >
                                                {tag}
                                            </CustomButton>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {substitutedRelatedFeaturesAccordingToTheTags.length > 0 ? (
                        <div className={styles.relatedFeatures}>
                            <p className={styles.title}>Check out the associated AY solution</p>
                            <div className={styles.features}>
                                {post.frontmatter.relatedFeatures
                                    ? post.frontmatter.relatedFeatures.map((relatedFeature: string, index: number) => (
                                          <div key={index} className={styles.relatedFeature}>
                                              {addFeatureButton(relatedFeature)}
                                          </div>
                                      ))
                                    : substitutedRelatedFeaturesAccordingToTheTags.map(
                                          (relatedFeature: string, index: number) => (
                                              <div key={index} className={styles.relatedFeature}>
                                                  {addFeatureButton(relatedFeature)}
                                              </div>
                                          )
                                      )}
                            </div>
                        </div>
                    ) : null}
                    {currentPerson ? (
                        <div className={styles.authorWrapper}>
                            <p className={styles.title}>Written by</p>

                            <div className={styles.person}>
                                <div className={styles.avatar}>
                                    <img className={styles.avatarImage} src={currentPerson.authorimage} />
                                </div>
                                <div className={styles.authorData}>
                                    <p className={styles.name}>{currentPerson.name}</p>
                                    <p className={styles.position}>{currentPerson.position}</p>
                                </div>
                            </div>

                            <div className={styles.description}>{currentPerson.shortbio}</div>
                        </div>
                    ) : null}
                </Col>
                <Col xs={12} xl={4}>
                    <div className={[styles.right_sidebar, "sticky-top"].join(" ")}>
                        {ads.horizontalAdLink ? (
                            <div className={styles.adsWrapper}>
                                <AdBannerWrapper
                                    style={{
                                        marginBottom: "10px",
                                    }}
                                    link={ads.horizontalAdLink}
                                >
                                    <div className={styles.horizontalAd}>
                                        <GatsbyImage image={horizontalAd!} alt="horizontal ad" />
                                    </div>
                                </AdBannerWrapper>
                                <AdBannerWrapper link={ads.verticalAdLink}>
                                    <div className={styles.verticalAd}>
                                        <GatsbyImage image={verticalAd!} alt="vertical ad" />
                                    </div>
                                </AdBannerWrapper>
                            </div>
                        ) : null}

                        <div className={styles.stay_updated_card}>
                            <div className={styles.stay_updated_card_header}>Stay Updated</div>

                            <div className={styles.stay_updated_card_text}>
                                No spam. Just the latest releases and tips, interesting articles, and exclusive
                                interviews in your inbox every week.
                            </div>

                            {hasSubscribed ? (
                                <div className={[styles.stay_updated_card_text].join(" ")}>Thanks for subscribing</div>
                            ) : (
                                <>
                                    <div className="d-flex flex-column">
                                        <SubscribeInput email={email} setEmail={setEmail} isValid={isValid} />
                                        <Link
                                            className={[
                                                styles.stay_updated_card_text,
                                                styles.stay_updated_card_link,
                                                "mt-2",
                                            ].join(" ")}
                                            to="/privacy"
                                        >
                                            Read about our privacy policy.
                                        </Link>
                                    </div>
                                    <button className={styles.stay_updated_card_button} onClick={onSubscribe}>
                                        Subscribe
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};
export default BlogPost;
