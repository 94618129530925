import { type PublicDimensionConfig, RenderDimension } from "../../types";

export const YieldPrebidWebDimensions = {
    prebid_version: {
        label: "Prebid Version",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    assertiveVersion: {
        label: "Assertive Version",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },

    // Custom
    timeout: {
        label: "Timeout",
        shouldLimit: true,
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    userState: {
        label: "User State",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    layout: {
        label: "Layout",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_1: {
        label: "Custom 1",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_2: {
        label: "Custom 2",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_3: {
        label: "Custom 3",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_4: {
        label: "Custom 4",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_5: {
        label: "Custom 5",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_6: {
        label: "Custom 6",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_7: {
        label: "Custom 7",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_8: {
        label: "Custom 8",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_9: {
        label: "Custom 9",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_10: {
        label: "Custom 10",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_11: {
        label: "Custom 11",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_12: {
        label: "Custom 12",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_13: {
        label: "Custom 13",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_14: {
        label: "Custom 14",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    custom_15: {
        label: "Custom 15",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
    },
    mapped_userState: {
        label: "User State - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_layout: {
        label: "Layout - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_1: {
        label: "Custom 1 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_2: {
        label: "Custom 2 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_3: {
        label: "Custom 3 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_4: {
        label: "Custom 4 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_5: {
        label: "Custom 5 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_6: {
        label: "Custom 6 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_7: {
        label: "Custom 7 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_8: {
        label: "Custom 8 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_custom_9: {
        label: "Custom 9 - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    // User
    newVsReturning: {
        label: "New vs. Returning",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    clientDepthSessions: {
        label: "Device Depth Sessions",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    sessionDepthPageViews: {
        description: "Number of PVs within a session",

        label: "Session Depth PV",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    sessionDepthImpressions: {
        description: "Number of impressions within a session",

        label: "Session Depth Imps",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    device: {
        label: "Device",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    window_innerSize: {
        label: "Window Size",
        row_type: "String",
        render: RenderDimension.PlainString,
        shouldLimit: true,
        uiGroup: "user",
    },
    window_scrollY: {
        description: "How many pixels users are scrolling down the page",

        label: "Scroll Depth",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    network_type: {
        label: "Network Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    network_effectiveType: {
        label: "Network Effective Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    network_downlink: {
        label: "Network Downlink",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    network_rtt: {
        label: "Network RTT (ping)",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    network_saveData: {
        label: "Network Data-Saver",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    operatingSystem: {
        label: "Operating System",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    browser: {
        label: "Browser",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    country: {
        label: "Country",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    countryCode: {
        label: "Country Code",

        row_type: "FixedString(2)",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    region: {
        label: "Region",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    continent: {
        label: "Continent",

        row_type: "FixedString(2)",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    language: {
        label: "Language",

        row_type: "FixedString(2)",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },

    // Source
    referrerType: {
        label: "Referrer Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    referrerDomain: {
        label: "Referrer Domain",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    referrer: {
        label: "Referrer",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    utmSource: {
        label: "UTM Source",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    utmMedium: {
        label: "UTM Medium",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    utmCampaign: {
        label: "UTM Campaign",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    utmTerm: {
        label: "UTM Term",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    utmContent: {
        label: "UTM Content",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "source",
    },
    mapped_utmSource: {
        label: "UTM Source - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_utmMedium: {
        label: "UTM Medium - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_utmCampaign: {
        label: "UTM Campaign - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_utmTerm: {
        label: "UTM Term - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    mapped_utmContent: {
        label: "UTM Content - Mapped",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "mapped",
    },
    // Content
    host: {
        label: "Domain",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.Link,

        uiGroup: "content",
    },
    domain_and_content: {
        label: "Page Domain Path",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.Link,

        uiGroup: "content",
    },
    entryPage: {
        label: "Entry Page",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
    content: {
        label: "Content",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
    pageLevel1: {
        label: "Page Level 1",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
    pageLevel2: {
        label: "Page Level 2",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
    pageLevel3: {
        label: "Page Level 3",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
    pageLevel4: {
        label: "Page Level 4",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
    experiments_key_value_raw: {
        label: "Experiments Key-Value (Raw)",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
        shouldLimit: true,
        hideFromApiDocs: true,
    },
    experiments_key_value: {
        label: "Experiments Key-Value",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "custom",
        isMultiAttribution: true,
        hideFromApiDocs: true,
    },
    pageLevel1Inf: {
        label: "Page Level 1 Inf",
        // Concat '' so that the formula is different from pageLevel1,
        // but results are the same.
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "content",
    },
    entityId: {
        label: "Entity Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    entityName: {
        label: "AY Entity",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    publisherName: {
        label: "AY Publisher",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    organizationName: {
        label: "AY Organization",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
    hourOfDay: {
        description: "Hour of the day, in the app's selected timezone.",

        label: "Hour of Day",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "time_based",
    },
    dayOfWeek: {
        description: "Day of the week, in the app's selected timezone.",

        label: "Day of the Week",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "time_based",
    },
    dayOfMonth: {
        description: "Day of the month, in the app's selected timezone.",

        label: "Day of the Month",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "time_based",
    },
    monthOfYear: {
        description: "Month of the year.",
        label: "Month on the year",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "time_based",
    },
    fullDate: {
        label: "Full Date",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "time_based",
    },
    manager_status: {
        description: "Yield Manager's status.",

        label: "Yield Manager Status",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "yield_manager",
    },
    manager_deployId: {
        label: "Yield Manager Deploy ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "yield_manager",
    },
    manager_deployedAt: {
        label: "Yield Manager Deployed At",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "yield_manager",
    },
    manager_versionId: {
        description: "Yield Manager's version id.",

        label: "Yield Manager Version ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "yield_manager",
    },
    manager_versionName: {
        description: "Yield Manager's version name.",
        label: "Yield Manager Version Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "yield_manager",
    },

    sessionId: {
        label: "Session Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },
} satisfies Record<string, Omit<PublicDimensionConfig, "id">>;
