import { CustomRangeConfig } from "../../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../../lib";

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([
        "by_minute",
        "by_10_minutes",
        "by_hour",
        "by_day",
        "by_week",
        "by_month",
        "by_quarter",
        "no_granularity",
    ]);
