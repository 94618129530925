import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import yield_ from "./yield";

const yieldConfig = yield_.metricTable;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([
        "by_minute",
        "by_10_minutes",
        "by_hour",
        "by_day",
        "by_week",
        "by_month",
        "by_quarter",
        "no_granularity",
    ])
    .withRawMetrics(yield_.rawMetrics)
    .withCalculatedMetricsIds(yield_.calculatedMetricsIds)
    .withCalculatedMetrics(yield_.metrics)
    .withMetricTable({
        initialMetrics: ["sessions", "rpm", "pageView_rpm", "session_rpm", "impressions_unfilled_n_house_rate"],
        setup: {
            ...yieldConfig.setup,
        },
    })
    .withDimensions(yield_.dimensionsConfig);
