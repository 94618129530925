//@ts-ignore
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";
import type { RawMetricsType } from "../eda/types";
import { STD } from "../utils/std";
import type { DimensionKeys } from "./dimension-keys.constants";

export type NonEmptyArray<T> = [T, ...T[]];
export type NullPartial<T> = { [P in keyof T]?: T[P] | undefined | null };

export const warningBannerClass = "warning-banners";
export const warningBannerClassSelector = ".warning-banners";
export const appHeaderId = "app-header";
export const appHeaderClassSelector = "#" + appHeaderId;

export const DASHBOARD_TAG_NAME_MAX_LENGTH = 25;

export const defaultLastNDays = 7;

export const level = {
    "-1": "undefined",
    undefined: -1,
    info: 1,
    1: "Info",
    warning: 5,
    5: "Warning",
    critical: 9,
    9: "Critical",
};

/**
 * A list of zulu timezones
 */
export const zuluTimezones = [
    "UTC",
    "Zulu",
    "GMT",
    "GMT0",
    "GMT-0",
    "GMT+0",
    "Etc/UTC",
    "Etc/Zulu",
    "Etc/GMT",
    "Etc/GMT0",
    "Etc/GMT-0",
    "Etc/GMT+0",
];

export const MAPPING_ALLOWED_ORIGINAL_DIMENSIONS_IDS: DimensionKeys[] = [
    // GAM dimensions
    "dfpAdvertiser",
    "dfpAdvertiserId",
    "dfpOrder",
    "dfpOrderId",
    "dfpLineItem",
    "dfpLineItemId",
    "dfpCreative",
    "adUnit",
    // UTM dimensions
    "utmCampaign",
    "utmContent",
    "utmMedium",
    "utmSource",
    "utmTerm",
    "deal",
    "layout",
    "userState",
    "custom_1",
    "custom_2",
    "custom_3",
    "custom_4",
    "custom_5",
    "custom_6",
    "custom_7",
    "custom_8",
    "custom_9",

    // amazon
    "amazonBidderId",
];

export const MAPPING_IDS_RENAMED = {
    dfpAdvertiser: "dfpAdvertiser",
    dfpAdvertiserId: "dfpAdvertiserId",
    dfpOrder: "dfpOrder",
    dfpOrderId: "dfpOrderId",
    dfpLineItem: "dfpLineItem",
    dfpLineItemId: "dfpLineItemId",
    dfpCreative: "dfpCreative",
    adUnit: "adUnit",
    utmCampaign: "session_utm_campaign",
    utmContent: "session_utm_content",
    utmMedium: "session_utm_medium",
    utmSource: "session_utm_source",
    utmTerm: "session_utm_term",
    deal: "ad_dealId",
    layout: "custom_layout",
    userState: "custom_userState",
    custom_1: "custom_1",
    custom_2: "custom_2",
    custom_3: "custom_3",
    custom_4: "custom_4",
    custom_5: "custom_5",
    custom_6: "custom_6",
    custom_7: "custom_7",
    custom_8: "custom_8",
    custom_9: "custom_9",
    amazonBidderId: "dfp_amazonBidderId",
};

export const MAPPING_IDS_RENAMED_REVERSED = STD.reverseKv(MAPPING_IDS_RENAMED);

export type FilterKeys = DimensionKeys | "startDate" | "endDate";

export const GetDailyDetailsLimit = 2000;

export const prettyDSP = {
    taboola: "Taboola",
    zemanta: "Zemanta",
    twitter: "Twitter",
    facebook: "Facebook",
    yahoo: "Yahoo",
    snapchat: "Snapchat",
    outbrain: "Outbrain",
    revcontent: "Revcontent",
    baidu: "Baidu",
    google_ads: "Google Ads",
    tiktok: "TikTok",
    pinterest: "Pinterest",
};
export const prettySSP = {
    nativo_ssp: "Nativo",
    yahoo_ssp: "Yahoo SSP",
    outbrain_ssp: "Outbrain SSP (Beta)",
    taboola_realtime_ssp: "Taboola Realtime (Beta)",
    taboola_ssp: "Taboola (Beta)",
    google_adsense: "AdSense Analytics (Alpha)",
    google_adsense_direct: "AdSense Direct (Alpha)",
    ogury: "OGury",
    loopme: "LoopMe",
    seedtag: "SeedTag",
};

export const DEFAULT_DSP_SPEND_JOIN_KEY = "campaign_id";
export const DEFAULT_DSP_YIELD_JOIN_KEY = "utmCampaign";

export const DEFAULT_DSP_SITE_JOIN_KEY = "site_name";
export const DEFAULT_DSP_SITE_YIELD_JOIN_KEY = "utmSource";

export const DEFAULT_DSP_SPENT_BY_SECTION_JOIN_KEY = "section_name";
export const DEFAULT_DSP_SPENT_BY_SECTION_YIELD_JOIN_KEY = "utmSource";

export const QUALITATIVE_COLORS = [
    "78, 218, 255",
    "255, 146, 76",
    "255, 86, 181",
    "85, 134, 223",
    "74, 209, 187",
    "157, 48, 255",
    "183, 210, 37",
    "153, 166, 212",
    "255, 227, 117",
    "112, 159, 175",
    "230, 124, 124",
    "70, 230, 195",
    "111, 113, 117",
    "105, 140, 255",
    "51, 255, 87",
    "164, 102, 255",
    "248, 131, 176",
    "241, 199, 54",
    "39, 208, 223",
    "255, 0, 41",
    "51, 120, 255",
    "255, 143, 70",
    "142, 156, 207",
    "188, 106, 59",
    "189, 189, 189",
    "54, 204, 179",
    "51, 87, 255",
    "50, 12, 132",
    "217, 104, 179",
    "117, 226, 255",
    "255, 209, 51",
    "221, 160, 221",
    "95, 158, 160",
    "51, 120, 255",
    "255, 143, 70",
    "245, 55, 148",
    "77, 201, 246",
    "78, 112, 173",
    "211, 170, 141",
    "105, 140, 255",
    "27, 133, 46",
    "189, 51, 255",
    "248, 131, 176",
    "255, 209, 51",
    "39, 208, 223",
    "255, 0, 41",
    "51, 120, 255",
    "255, 143, 70",
    "194, 126, 182",
    "191, 57, 71",
    "70, 51, 51",
    "141, 211, 199",
];
export const FACEBOOK_API_VERSION = "v20.0";
export const YAHOO_API_URL = "https://api.gemini.yahoo.com/v3";
export const STATUSPAGE_SUMMARY_URL = "https://status.assertiveyield.com/api/v2/summary.json";
export const TWITTER_API_URL = "https://ads-api.twitter.com/11";
export const GOOGLE_ADS_API_URL = "https://googleads.googleapis.com/v16";
export const BAIDU_ADS_API_DATA_URL = "https://api.mediago.io/data/v1";
export const BAIDU_ADS_API_MANAGE_URL = "https://api.mediago.io/manage/v1";
export const NATIVO_API_URL = "https://api.nativo.com/v2";
export const OUTBRAIN_API_URL = "https://api.outbrain.com/amplify/v0.1";
export const OUTBRAIN_ENGAGE_API_URL = "https://api.outbrain.com/engage";
export const TIKTOK_ADS_API_URL = "https://business-api.tiktok.com/open_api/v1.3";
export const PINTEREST_API_URL = "https://api.pinterest.com/v5";

export const CUSTOM_LABELS = [
    "custom_1",
    "custom_2",
    "custom_3",
    "custom_4",
    "custom_5",
    "custom_6",
    "custom_7",
    "custom_8",
    "custom_9",

    "custom_10",
    "custom_11",
    "custom_12",
    "custom_13",
    "custom_14",
    "custom_15",
] as const;

export type CustomLabelIds = (typeof CUSTOM_LABELS)[number];
export type CustomLabelsStored = Partial<Record<CustomLabelIds, string>>;

// five minutes
export const NETWORK_TIMEOUT_FOR_DOWNLOADS = 1000 * 60 * 5;

export const EDA_REQUEST_TIMEOUT_MS = 1000 * 60 * 20;

export const EXPOSED_SPENT_API_AD_DIMENSION_IDS_MAPPING: Record<string, DimensionKeys> = {
    platform: "buy_report_platform",
    campaign_name: "buy_report_campaign_name",
    adset_name: "buy_report_adset_name",
    ad_name: "buy_report_ad_name",
    campaign_id: "buy_report_campaign_id",
    adset_id: "buy_report_adset_id",
    ad_id: "buy_report_ad_id",
    sub_account_id: "buy_report_subAccountId",
    sub_account_name: "buy_report_subAccountName",
};

export const EXPOSED_SPENT_API_PUBLISHER_DIMENSION_IDS_MAPPING: Record<string, DimensionKeys> = {
    site: "site_report_site",
    platform: "site_report_platform",
    site_name: "site_report_site_name",
    site_url: "site_report_site_url",
    site_id: "site_report_site_id",
    campaign_name: "site_report_campaign_name",
    campaign_id: "site_report_campaign_id",
    sub_account_id: "site_report_subAccountId",
    sub_account_name: "site_report_subAccountName",
};

export const EXPOSED_SPENT_API_SECTION_DIMENSION_IDS_MAPPING: Record<string, DimensionKeys> = {
    platform: "spent_by_section_report_platform",
    section_name: "spent_by_section_report_section_name",
    section_url: "spent_by_section_report_section_url",
    section_id: "spent_by_section_report_section_id",
    campaign_name: "spent_by_section_report_campaign_name",
    campaign_id: "spent_by_section_report_campaign_id",
    sub_account_id: "spent_by_section_report_subAccountId",
    sub_account_name: "spent_by_section_report_subAccountName",
};

export const EMPTY_FILTER_PLACEHOLDER = "empty_filter_placeholder";

export const WELCOME_METRICS = [
    //
    "session_starts",
    "impressions",
    "revenue",
    "session_start_rpm",
] as const;
export const ORGANIZATION_BASED_URLS = [
    "/view/request-reduction/server/samples",
    "/view/request-reduction/server/threshold",
    "/view/request-reduction/server/health-report",
    "/view/request-reduction/server/rpb",
    "/view/request-reduction/server/uplift",
    "/request-reduction/server/settings",
    "/request-reduction/server/health",
    "/request-reduction/server/optimization",
    "/view/connected-ssps/discrepancy",
    "/view/connected-ssps/financial",
    "/view/traffic-shaping/server",
    "/connected-ssps/settings",
];

export const LATENCY_THRESHOLDS_TRAFFIC_SHAPING_SERVER = {
    p50: { yellow: 0.5, red: 0.75 },
    p95: { yellow: 0.75, red: 1.0 },
    p99: { yellow: 1.0, red: 1.5 },
} as const;

export const CH_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const NETLIFY_CMS_HOST = "https://elegant-gnome-5d3174.netlify.app";
export const ALLOWED_TRAFFIC_SHAPING_MAPPINGS_DIMENSIONS_BIDDER_ID = "bidder_id";
export const ALLOWED_TRAFFIC_SHAPING_MAPPINGS_DIMENSIONS = [
    {
        id: ALLOWED_TRAFFIC_SHAPING_MAPPINGS_DIMENSIONS_BIDDER_ID,
        label: "Bidder",
    },
];

export const NOTIFICATION_GROUPS_EMAIL_KEY = "email" as const;
export const NOTIFICATION_GROUPS_SLACK_WEBHOOK_KEY = "slack_wh" as const;

export const API_UNSUPPORTED_YIELD_CALCULATED_METRICS = [
    "dynamicAllocation_predicted_revenue_r2_server",
    "dynamicAllocation_predicted_revenue_r2",
] as const;

export const DEFAULT_ISS_MOE_SIG = {
    moe: 0.02,
    significance: 0.95,
} as const;

export const RANGE_REALTIME_NOW = "RANGE_REALTIME_NOW" as const;
export type RANGE_REALTIME_NOW = typeof RANGE_REALTIME_NOW;

const ALL_RANGE_KEYS = [
    "Last 5 Minutes",
    "Last 10 Minutes",
    "Last 30 Minutes",
    "Last 1 Hour",
    "Last 3 Hours",
    "Last 6 Hours",
    "Last 12 Hours",
    "Last 24 Hours",
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last Month",
    "Last Quarter",
    "Last Year",
    "Maximum",
] as const;

export type RangeKeys = (typeof ALL_RANGE_KEYS)[number];

export const RANGE_KEYS = {
    "Last 5 Minutes": "Last 5 Minutes",
    "Last 10 Minutes": "Last 10 Minutes",
    "Last 30 Minutes": "Last 30 Minutes",
    "Last 1 Hour": "Last 1 Hour",
    "Last 3 Hours": "Last 3 Hours",
    "Last 6 Hours": "Last 6 Hours",
    "Last 12 Hours": "Last 12 Hours",
    "Last 24 Hours": "Last 24 Hours",
    Today: "Today",
    Yesterday: "Yesterday",
    "Last 7 Days": "Last 7 Days",
    "Last 30 Days": "Last 30 Days",
    "This Month": "This Month",
    "Last Month": "Last Month",
    "Last Quarter": "Last Quarter",
    "Last Year": "Last Year",
    Maximum: "Maximum",
} as const;

Object.entries(RANGE_KEYS).forEach(([k, v]) => {
    if (k != v) {
        throw new Error("range keys kv must match");
    }
});

export type RANGE_VALUE_TYPE = (typeof RANGE_KEYS)[keyof typeof RANGE_KEYS];

export type DateShape = moment.Moment | RANGE_VALUE_TYPE | string;

export const SCHEDULE_FREQUENCY = {
    UNSCHEDULED: "UNSCHEDULED",
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
    WEEKLY: "WEEKLY",
} as const;

export type Frequency = keyof typeof SCHEDULE_FREQUENCY;
export const LAYOUTS_DOMAIN_REGEX = /^(?!:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}$/;
export const LAYOUTS_DOMAIN_REGEX_ERROR = "Domain should be in the format of 'example.com'";
export const LAYOUTS_PRODUCTION_URL = "https://assertive.page";

export const LAYOUTS_API_BASE_REGEX =
    /^(?:(?:https?):\/\/)?([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[^\s?#]*)?(\?[^\s#]*(?:&[^\s#]*)*)?(#[^\s]*)?$/;
export const LAYOUTS_API_BASE_REGEX_ERROR = "Api Url should be in the format of 'example.com/wp.json'";

export const BASE_JOB_PRIORITY = 2000;
export const SSP_WORKER_QUEUE_ATTEMPTS = 3;
export const DSP_WORKER_QUEUE_ATTEMPTS = 3;
export const SSP_BACKFILL_WORKER_QUEUE_NAME = "SspBackfillv8";
export const DSP_BACKFILL_WORKER_QUEUE_NAME = "DspBackfillv6";
export const YM_DEPLOYMENT_SUMMARY_REPORT_QUEUE_NAME = "YMDeploymentSummaryReport";
export const YM_DEPLOYMENT_INTERNAL_NOTIFICATIONS_QUEUE_NAME = "YMDeploymentInternalNotifications";
export const DFP_PRE_IMPORT_QUEUE_NAME = "DfpPreImportv8";
export const NO_CUSTOMER_PRIORITY_PENALTY = 20000;
export const YYYY_MM_DD_FORMAT = "YYYY-MM-DD";
export const SUITE_AUTH_TOKEN = "suite_auth";

export const ADMIN_USER_ID = "p5T5CkFFhTwH9EbRf";
export const INTERNAL_API_USER_ID = "qo3bJfdoKnt8sSfeR";
export const INTERNAL_TEMPLATES_USER_ID = "dcrXp7sceturaTFxj";
export const INTERNAL_DEVELOPER_USER_ID = "7X9RhCCfesty2Erbp";

export const DEV_IDS = [INTERNAL_DEVELOPER_USER_ID, INTERNAL_API_USER_ID];
export const ADMIN_IDS = [ADMIN_USER_ID];
export const ADMIN_OR_DEV_IDS = [...DEV_IDS, ...ADMIN_IDS];

export const ROLLBAR_IGNORED_MESSAGES = [
    "Query was interrupted. Please refresh the report.",
    "The user aborted a request",
    "The operation was aborted",
    "AbortError",
    "ResizeObserver",
    "Fetch is aborted",
    "Request signal is aborted",
    "signal is aborted without reason",
    "Premature close",
    "missing devLockJWT",
    "Failed to fetch",
    "aborted",
    "max version count doesn't match",
];

export const FOOTER_HEIGHT = 50;
export const SPECIAL_SORT_BY_DIMENSIONS = [
    "hourOfDay",
    "dayOfWeek",
    "dayOfMonth",
    "request_reduction_server_samples_threshold",
    "fullDate",
];

export const sortBySpecialSortDimensions = (dimension: DimensionKeys, unsortedGroups: string[]): string[] => {
    switch (dimension) {
        case "request_reduction_server_samples_threshold": {
            return unsortedGroups.sort((a, b) => {
                const da = parseFloat(a as any);
                const db = parseFloat(b as any);
                return da - db;
            });
        }
        case "hourOfDay": {
            return unsortedGroups.sort((a, b) => {
                const ha = parseInt(String(a).replace("h", ""), 10);
                const hb = parseInt(String(b).replace("h", ""), 10);
                return ha - hb;
            });
        }

        case "dayOfWeek": {
            return unsortedGroups.sort((a, b) => {
                const dayLookup = {
                    Monday: 1,
                    Tuesday: 2,
                    Wednesday: 3,
                    Thursday: 4,
                    Friday: 5,
                    Saturday: 6,
                    Sunday: 7,
                };
                const va = (dayLookup as any)[a as any] ?? 0;
                const vb = (dayLookup as any)[b as any] ?? 0;
                return va - vb;
            });
        }

        case "dayOfMonth": {
            return unsortedGroups.sort((a, b) => {
                const da = parseInt(a as any, 10);
                const db = parseInt(b as any, 10);
                return da - db;
            });
        }
        case "fullDate": {
            return unsortedGroups.sort((a, b) => {
                const da = new Date(a as any).getTime();
                const db = new Date(b as any).getTime();
                return da - db;
            });
        }
        default: {
            throw new Error(`invalid dimension: ${dimension}`);
        }
    }
};
export const NOWRAP_CN = `tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap`;
export const typesMillion: RawMetricsType[] = [
    "prebid_revenue",
    "prebid_won_revenue",
    "prebid_lost_revenue",
    "direct_revenue",
    "aps_revenue",
    "dynamicAllocation_revenue",
    "dynamicAllocation_predicted_revenue",
    "dynamicAllocation_predicted_revenue_server",
    "dynamicAllocation_revenue_with_forecast",
    "dynamicAllocation_revenue_with_forecast_client",
    "lineItem_revenue",
    "prebid_report_bids_revenue",
    "prebid_report_wins_revenue",
    "prebid_incremental_value",
    "acquisition_cost",
    "outbrain_revenue",
    "taboola_revenue",
    "yahooGemini_revenue",
    "adSense_revenue", //TODO: confirm it
    "floor",
    "prebid_report_floor_sum",
];

export const million = 1_000_000.0;

export enum HealthStatus {
    red = "red",
    yellow = "yellow",
    green = "green",
    grey = "grey",
}
export const DOCS_HOST = "https://meek-toffee-74f088.netlify.app";
