import Topo from "@hapi/topo";
import type { CustomRangeConfig } from "../codecs/custom-range-config.codec";
import type { RangeKeys } from "../constants/_constants";
import type { TimeGranularity } from "../constants/time-granularity.constants";
import { STD } from "../utils/std";
import type { PublicRawMetricConfig } from "./raw-metrics.service";
import {
    type AllPossibleMetricsMap,
    CalculationType,
    type IMetric,
    type IMetricReturns,
    type MainMetricsType,
    type MetricCalculationFormula,
    type PublicDimensionConfig,
    type RawMetricsType,
} from "./types";

// preprocess metrics
// regex: use generic regex because variable names change when the js is minified
const re = /\w\.(\w+)/g;

function addFormulaDependenciesAndId(ms: AllPossibleMetricsMap): void {
    Object.entries(ms).forEach(([id, m]) => {
        (m as any).id = id;
    });
    Object.values(ms).forEach(m => {
        if (m.formula) {
            const dependencies: (MainMetricsType | RawMetricsType)[] = [];
            let match;
            do {
                // be aware of the stateful regex
                // https://stackoverflow.com/questions/11477415/why-does-javascripts-regex-exec-not-always-return-the-same-value
                match = re.exec(m.formula.toString());
                //@ts-ignore
                if (match && match[1] && !dependencies.includes(match[1])) {
                    //@ts-ignore
                    dependencies.push(match[1]);
                }
            } while (match);

            (m as any).formulaDependencies = dependencies;
        } else {
            (m as any).formulaDependencies = [];
        }
    });

    Object.values(ms).forEach(m => {
        if (m.calculationType === CalculationType.Sum) {
            m.shareOfVoiceChildren = m.formulaDependencies.filter(
                it => Object.keys(ms).includes(it) && it != m.id
            ) as any;
        } else {
            m.shareOfVoiceChildren = [];
        }
    });
}
export const sortMetricsTopological = <T extends MetricCalculationFormula>(it: readonly T[]): T[] => {
    const sorter = new Topo.Sorter<T>();
    it.forEach(metric => {
        sorter.add(metric, {
            after: metric.formulaDependencies?.filter(dep => dep !== metric.id) ?? [],
            group: metric.id,
        });
    });
    return sorter.nodes;
};

function addRawDependencies(
    ms: AllPossibleMetricsMap,
    rawMetrics: Record<string, PublicRawMetricConfig>,
    otherMetrics: PlacementBuiltConfig<string, string, string> | undefined
): void {
    sortMetricsTopological(Object.values(ms)).forEach(m => {
        if (m.formulaDependencies) {
            const rawDependencies: RawMetricsType[] = [];

            m.formulaDependencies.forEach(dep => {
                const isRawMetricKey = rawMetrics.hasOwnProperty(dep);
                if (isRawMetricKey) {
                    rawDependencies.push(dep as any);
                } else if (otherMetrics != null && (otherMetrics as any)[dep]) {
                    const m = (otherMetrics as any)[dep].rawDependencies;
                    if (m == null) {
                        throw new Error(`metric ${dep} is missing rawDependencies`);
                    }
                    rawDependencies.push(...m);
                } else if ((ms as any)[dep]) {
                    const m = (ms as any)[dep].rawDependencies;
                    if (m == null) {
                        throw new Error(
                            `metric ${dep} is missing rawDependencies, check if the 'rawMetrics' are correctly configured`
                        );
                    }
                    rawDependencies.push(...m);
                }
            });
            m.rawDependencies = rawDependencies;
            rawDependencies.forEach(dep => {
                const config = rawMetrics[dep].config;
                if (config) {
                    m.config = { ...m.config, ...config };
                }
            });
        }
    });
}

export class EdaPlacementBuilder {
    constructor() {}
    public withCustomRange(customRangeConfig: CustomRangeConfig) {
        return new TimeRangesStageBuilder(customRangeConfig);
    }
}

class TimeRangesStageBuilder {
    private customRangeConfig: CustomRangeConfig;

    constructor(customRangeConfig: CustomRangeConfig) {
        this.customRangeConfig = customRangeConfig;
    }

    public withTimeRanges(timeRanges: RangeKeys[]) {
        return new TimeGranularityStateBuilder(this.customRangeConfig, timeRanges);
    }
}

class TimeGranularityStateBuilder {
    private customRangeConfig: CustomRangeConfig;
    private timeRanges: RangeKeys[];

    constructor(customRangeConfig: CustomRangeConfig, timeRanges: RangeKeys[]) {
        this.customRangeConfig = customRangeConfig;
        this.timeRanges = timeRanges;
    }
    public withTimeGranularities(timeGranularities: TimeGranularity[]): RawMetricsStageBuilder {
        return new RawMetricsStageBuilder(timeGranularities, this.customRangeConfig, this.timeRanges);
    }
}

class RawMetricsStageBuilder {
    private timeGranularities: TimeGranularity[];
    private customRangeConfig: CustomRangeConfig;
    private timeRanges: RangeKeys[];

    constructor(timeGranularities: TimeGranularity[], customRangeConfig: CustomRangeConfig, timeRanges: RangeKeys[]) {
        this.timeGranularities = timeGranularities;
        this.customRangeConfig = customRangeConfig;
        this.timeRanges = timeRanges;
    }

    public withRawMetrics<R extends string>(
        vals: Record<R, PublicRawMetricConfig>
    ): CalculatedMetricsIdsStageBuilder<R> {
        return new CalculatedMetricsIdsStageBuilder(
            vals,
            this.timeGranularities,
            this.customRangeConfig,
            this.timeRanges
        );
    }
}

class CalculatedMetricsIdsStageBuilder<R extends string> {
    private rawMetrics: Record<R, PublicRawMetricConfig>;
    private timeGranularities: TimeGranularity[];
    private customRangeConfig: CustomRangeConfig;
    private timeRanges: RangeKeys[];

    constructor(
        rawMetrics: Record<R, PublicRawMetricConfig>,
        timeGranularities: TimeGranularity[],
        customRangeConfig: CustomRangeConfig,
        timeRanges: RangeKeys[]
    ) {
        this.rawMetrics = rawMetrics;
        this.timeGranularities = timeGranularities;
        this.customRangeConfig = customRangeConfig;
        this.timeRanges = timeRanges;
    }

    public withCalculatedMetricsIds<C extends string>(
        calculatedMetricsIds: readonly C[]
    ): CalculatedMetricsStageBuilder<R, C> {
        return new CalculatedMetricsStageBuilder(
            this.rawMetrics,
            calculatedMetricsIds,
            this.timeGranularities,
            this.customRangeConfig,
            this.timeRanges
        );
    }
}

export type TabMeta = {
    name: string;
    explanation: string;
};
export type MetricTableInput<T, TabId extends string, ColumnId extends string> = {
    initialMetrics: T[];
    setup: {
        tableMapping: Record<TabId, Partial<Record<ColumnId, T[]>>>;
        tabMeta: Record<TabId, TabMeta>;
        columnNames: Record<ColumnId, string>;
    };
};
export type PlacementBuiltConfig<R extends string, C extends string, D extends string> = {
    metrics: Record<C, IMetricReturns<C, R>>;
    rawMetrics: Record<R, PublicRawMetricConfig>;
    calculatedMetricsIds: readonly C[];
    dimensionsConfig: Record<D, PublicDimensionConfig>;
    metricTable: MetricTableInput<C, string, string>;
    timeGranularities: TimeGranularity[];
    customRangeConfig: CustomRangeConfig;
    timeRanges: RangeKeys[];
};

class CalculatedMetricsStageBuilder<R extends string, C extends string> {
    private rawMetrics: Record<R, PublicRawMetricConfig>;
    private calculatedMetricsIds: readonly C[];
    private timeGranularities: TimeGranularity[];
    private customRangeConfig: CustomRangeConfig;
    private timeRanges: RangeKeys[];

    constructor(
        rawMetrics: Record<R, PublicRawMetricConfig>,
        calculatedMetricsIds: readonly C[],
        timeGranularities: TimeGranularity[],
        customRangeConfig: CustomRangeConfig,
        timeRanges: RangeKeys[]
    ) {
        this.rawMetrics = rawMetrics;
        this.calculatedMetricsIds = calculatedMetricsIds;
        this.timeGranularities = timeGranularities;
        this.customRangeConfig = customRangeConfig;
        this.timeRanges = timeRanges;
    }
    public withCalculatedMetrics(
        metrics: Record<C, IMetric<C, R>>,
        other?: PlacementBuiltConfig<string, string, string>
    ): MetricsTableStageBuilder<R, C> {
        return new MetricsTableStageBuilder(
            this.rawMetrics,
            this.calculatedMetricsIds,
            metrics,
            other,
            this.timeGranularities,
            this.customRangeConfig,
            this.timeRanges
        );
    }
}

class MetricsTableStageBuilder<R extends string, C extends string> {
    private rawMetrics: Record<R, PublicRawMetricConfig>;
    private calculatedMetricsIds: readonly C[];
    private metrics: Record<C, IMetric<C, R>>;
    private otherMetricsConfig: PlacementBuiltConfig<string, string, string> | undefined;
    private timeGranularities: TimeGranularity[];
    private customRangeConfig: CustomRangeConfig;
    private timeRanges: RangeKeys[];

    constructor(
        rawMetrics: Record<R, PublicRawMetricConfig>,
        calculatedMetricsIds: readonly C[],
        metrics: Record<C, IMetric<C, R>>,
        otherMetrics: PlacementBuiltConfig<string, string, string> | undefined,
        timeGranularities: TimeGranularity[],
        customRangeConfig: CustomRangeConfig,
        timeRanges: RangeKeys[]
    ) {
        this.rawMetrics = rawMetrics;
        this.calculatedMetricsIds = calculatedMetricsIds;
        this.metrics = metrics;
        this.otherMetricsConfig = otherMetrics;
        this.timeGranularities = timeGranularities;
        this.customRangeConfig = customRangeConfig;
        this.timeRanges = timeRanges;
    }

    public withMetricTable<TabId extends string, ColumnId extends string>(
        inp: MetricTableInput<C, TabId, ColumnId>
    ): DimensionsStageBuilder<R, C> {
        return new DimensionsStageBuilder(
            this.rawMetrics,
            this.calculatedMetricsIds,
            this.metrics,
            this.otherMetricsConfig,
            inp,
            this.timeGranularities,
            this.customRangeConfig,
            this.timeRanges
        );
    }
}

const createDimensions = <D extends string>(
    it: Record<D, Omit<PublicDimensionConfig, "id">>
): Record<D, PublicDimensionConfig> =>
    STD.getEntries(it).reduce((acc, [key, value]) => {
        acc[key] = {
            id: key,
            ...value,
        };
        return acc;
    }, {} as any);

class DimensionsStageBuilder<R extends string, C extends string> {
    private rawMetrics: Record<R, PublicRawMetricConfig>;
    private calculatedMetricsIds: readonly C[];
    private metrics: Record<C, IMetric<C, R>>;
    private otherMetricsConfig: PlacementBuiltConfig<string, string, string> | undefined;
    private metricTable: MetricTableInput<C, string, string>;
    private timeGranularities: TimeGranularity[];
    private customRangeConfig: CustomRangeConfig;
    private timeRanges: RangeKeys[];

    constructor(
        rawMetrics: Record<R, PublicRawMetricConfig>,
        calculatedMetricsIds: readonly C[],
        metrics: Record<C, IMetric<C, R>>,
        otherMetrics: PlacementBuiltConfig<string, string, string> | undefined,
        metricTable: MetricTableInput<C, string, string>,
        timeGranularities: TimeGranularity[],
        customRangeConfig: CustomRangeConfig,
        timeRanges: RangeKeys[]
    ) {
        this.rawMetrics = rawMetrics;
        this.calculatedMetricsIds = calculatedMetricsIds;
        this.metrics = metrics;
        this.otherMetricsConfig = otherMetrics;
        this.metricTable = metricTable;
        this.timeGranularities = timeGranularities;
        this.customRangeConfig = customRangeConfig;
        this.timeRanges = timeRanges;
    }

    public withDimensions<D extends string>(
        dimensionsConfig: Record<D, Omit<PublicDimensionConfig, "id">>
    ): PlacementBuiltConfig<R, C, D> {
        const metrics = this.metrics;
        const other = this.otherMetricsConfig;
        if (
            Object.keys(metrics).length !== this.calculatedMetricsIds.length ||
            !this.calculatedMetricsIds.every(id => Object.keys(metrics).includes(id)) ||
            !Object.keys(metrics).every(id => this.calculatedMetricsIds.includes(id as any))
        ) {
            throw new Error("Keys of metrics do not match calculatedMetricsIds");
        }

        addFormulaDependenciesAndId(metrics);

        addRawDependencies(metrics, this.rawMetrics, other);
        return {
            metrics: metrics as any,
            rawMetrics: this.rawMetrics,
            calculatedMetricsIds: this.calculatedMetricsIds,
            dimensionsConfig: createDimensions(dimensionsConfig),
            metricTable: this.metricTable,
            timeGranularities: this.timeGranularities,
            customRangeConfig: this.customRangeConfig,
            timeRanges: this.timeRanges,
        };
    }
}
