import type { ReportPlacementKeyType } from "../constants/placement.constants";
import ad_revenue from "./data/ad_revenue";
import buy_ad from "./data/buy_ad";
import buy_adset from "./data/buy_adset";
import buy_campaign from "./data/buy_campaign";
import discrepancy_revenue from "./data/discrepancy_revenue";
import error_analytics from "./data/error_analytics";
import error_log from "./data/error_log";
import financial from "./data/financial";
import flooring from "./data/flooring";
import industry from "./data/industry";
import prebid from "./data/prebid";
import request_reduction_client_v2 from "./data/request_reduction_client_v2";
import request_reduction_server_latency from "./data/request_reduction_server_latency";
import request_reduction_server_samples_predict from "./data/request_reduction_server_samples_predict";
import request_reduction_server_samples_profile from "./data/request_reduction_server_samples_profile";
import segments from "./data/segments";
import session_revenue from "./data/session_revenue";
import session_time from "./data/session_time";
import site from "./data/site";
import spent_by_section from "./data/spent_by_section";
import traffic_shaping_server_flooring from "./data/traffic_shaping_server_flooring";
import view_dispersion from "./data/view_dispersion";
import web from "./data/web";
import yield_ from "./data/yield";
import type { PlacementBuiltConfig } from "./lib";

export const PLACEMENTS_METRIC_CONFIG = {
    buy_campaign,
    buy_adset,
    buy_ad,
    industry,
    ad_revenue,
    site,
    spent_by_section,
    request_reduction_client_v2,
    request_reduction_server_latency,
    request_reduction_server_samples_predict,
    request_reduction_server_samples_profile,
    traffic_shaping_server_flooring,
    discrepancy_revenue,
    financial,
    error_log,
    web,
    error_analytics,
    session_revenue,
    session_time,
    view_dispersion: view_dispersion,
    flooring,
    yield: yield_,
    segments,
    prebid,
} as const satisfies Record<ReportPlacementKeyType, PlacementBuiltConfig<string, string, string>>;
