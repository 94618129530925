import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { REVENUE_MULTIPLIER } from "../../constants/revenue-multiplier.constants";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    actual_revenue: {
        hideFromApi: true,
    },
    assertive_revenue: {
        hideFromApi: true,
    },
    assertive_impressions: {
        hideFromApi: true,
    },
    actual_impressions: {
        hideFromApi: true,
    },
} as const satisfies Record<string, PublicRawMetricConfig>;
export type DiscrepancyRevenueReportRawMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "actual_revenue",
    "assertive_impressions",
    "actual_impressions",
    "assertive_revenue",
    "calculated_revenue_discrepancy",
    "calculated_revenue_discrepancy_percentage",
    "calculated_impressions_discrepancy",
    "calculated_impressions_discrepancy_percentage",
] as const;
export type DiscrepancyRevenueReportMetricsType = (typeof CALC_IDS)[number];

const getDiscrepancyPercentage = (flatDiscrepancy: number, actualValue: number) => {
    if (actualValue === null || actualValue === 0) {
        return 100;
    }
    return (flatDiscrepancy / actualValue) * 100;
};

const DIMENSIONS = {
    discrepancy_revenue_bidder: {
        label: "SSP Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    discrepancy_revenue_placement: {
        label: "Placement",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type DiscrepancyRevenueReportDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.date)
    .withTimeRanges(["Last 7 Days", "Last 30 Days", "This Month", "Last Month"])
    .withTimeGranularities(["by_day", "by_week", "by_month"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        calculated_revenue_discrepancy: {
            label: "Revenue Discrepancy",
            explanation: "Revenue Discrepancy between SSP connections and Assertive Events in percentage",
            formula: m => m.actual_revenue - m.assertive_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        calculated_revenue_discrepancy_percentage: {
            label: "Revenue Discrepancy Percentage",
            explanation: "Revenue Discrepancy percentage between SSP connections and Assertive Events in percentage",
            formula: m => getDiscrepancyPercentage(m.calculated_revenue_discrepancy, m.actual_revenue),
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 0,
            keepFormulaFalsyAsFalsy: true,
            objective: OptimizationObjective.NoObjective,
        },
        calculated_impressions_discrepancy: {
            label: "Impressions Discrepancy",
            explanation: "Impressions Discrepancy between SSP connections and Assertive Events",
            formula: m => m.actual_impressions - m.assertive_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        calculated_impressions_discrepancy_percentage: {
            label: "Impressions Discrepancy Percentage",
            explanation:
                "Impressions Discrepancy percentage between SSP connections and Assertive Events in percentage",
            formula: m => getDiscrepancyPercentage(m.calculated_impressions_discrepancy, m.actual_impressions),
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 0,
            keepFormulaFalsyAsFalsy: true,
            objective: OptimizationObjective.NoObjective,
        },
        assertive_revenue: {
            label: "Assertive Revenue",
            explanation: "Assertive revenue represented from events",
            formula: m => m.assertive_revenue / REVENUE_MULTIPLIER,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        actual_revenue: {
            label: "Actual Revenue",
            explanation: "Actual revenue represented from SSP's connections",
            formula: m => m.actual_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_impressions: {
            label: "Actual Impressions",
            explanation: "Actual impressions represented from SSP's connections",
            formula: m => m.actual_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        assertive_impressions: {
            label: "Assertive Impressions",
            explanation: "Assertive impressions represented from events",
            formula: m => m.assertive_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
    })
    .withMetricTable({
        initialMetrics: [
            "assertive_revenue",
            "actual_revenue",
            "calculated_revenue_discrepancy",
            "calculated_revenue_discrepancy_percentage",
        ],
        setup: {
            tabMeta: {
                overall: { name: "Overall", explanation: "Assertive and SSP connections discrepancy metrics" },
            },
            columnNames: {
                revenue: "Revenue",
                impressions: "Impressions",
            },
            tableMapping: {
                overall: {
                    revenue: [
                        "assertive_revenue",
                        "actual_revenue",
                        "calculated_revenue_discrepancy",
                        "calculated_revenue_discrepancy_percentage",
                    ],
                    impressions: [
                        "assertive_impressions",
                        "actual_impressions",
                        "calculated_impressions_discrepancy",
                        "calculated_impressions_discrepancy_percentage",
                    ],
                },
            },
        },
    })
    .withDimensions(DIMENSIONS);
