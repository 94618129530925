// extracted by mini-css-extract-plugin
export var bigPost = "styles-module--bigPost--224a0";
export var buttonWrapper = "styles-module--buttonWrapper--6caa1";
export var circleLight = "styles-module--circleLight--0c8af";
export var contentWrapper = "styles-module--contentWrapper--706ae";
export var content_wrapper = "styles-module--content_wrapper--1b755";
export var header = "styles-module--header--59b2f";
export var headerContent = "styles-module--headerContent--40edb";
export var headerWrapper = "styles-module--headerWrapper--95376";
export var mainSubtitle = "styles-module--mainSubtitle--d0875";
export var mainTitle = "styles-module--mainTitle--953f0";
export var resultsWrapper = "styles-module--resultsWrapper--ac295";
export var search = "styles-module--search--7d7dc";
export var sliderAction = "styles-module--sliderAction--190c8";
export var smallPost = "styles-module--smallPost--9fd60";
export var smallPosts = "styles-module--smallPosts--07db1";