import { CustomRangeConfig } from "../../../codecs/custom-range-config.codec";
import { manualISSStats } from "../../../utils/iss";
import { EdaPlacementBuilder } from "../../lib";
import { CalculationType, OptimizationObjective } from "../../types";
import yield_ from "../yield";
import { CalculatedYieldMetricsIds } from "../yield/yield.calculated-metrics";

const yieldTableConfig = yield_.metricTable;

const CALC_IDS = [
    "buy_report_clicks",
    "buy_report_sessions",
    "buy_report_session_click_ratio",
    "buy_report_cpc",
    "buy_report_spent",
    "buy_report_roi",
    "buy_report_roi_with_forecast",
    "buy_report_profit",
    "buy_report_profit_with_forecast",
    "buy_report_click_rpm",
    "buy_report_click_rpm_with_forecast",
    "buy_report_click_revenue",
    "buy_report_click_revenue_with_forecast",
    "buy_report_cps",
    "buy_report_cpai",
    "buy_report_new_sessions",
    "buy_report_ffi",
    ...CalculatedYieldMetricsIds,
] as const;
export type BuyReportMetricsType = (typeof CALC_IDS)[number];

const RAW_METRICS = {
    ...yield_.rawMetrics,
    clicks_bought: {},
    spent: {},
} as const;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 1 Hour",
        "Last 3 Hours",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities(["by_hour", "by_day", "by_week", "by_month"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        ...yield_.metrics,
        //buy
        buy_report_clicks: {
            label: "Clicks Bought",
            explanation: "Clicks bought",
            formula: m => m.clicks_bought,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        buy_report_sessions: {
            label: "New Sessions",
            explanation: "User Sessions",
            formula: m => m.session_starts,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_session_click_ratio: {
            label: "Session Click Ratio",
            explanation: "New Sessions / Clicks",
            formula: m => m.buy_report_sessions / m.buy_report_clicks,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_cpc: {
            label: "CPC",
            explanation: "Cash per Click",
            formula: m => m.buy_report_spent / m.buy_report_clicks,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_spent_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        buy_report_spent: {
            label: "Spent",
            explanation: "Amount spent",
            formula: m => m.spent,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        buy_report_roi: {
            label: "ROI",
            explanation: "Return on Investment (Revenue / Spent)",
            formula: m => m.revenue / m.buy_report_spent,
            // If we interpret ROI as CPC/REV_PER_CLICK we can use one of the children metrics to calculate significance.
            // It's a hack but we can't do better than this.
            issStats: manualISSStats.buy_revenue_per_click,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_roi_with_forecast: {
            label: "ROI with Forecast",
            explanation: "Return on Investment (Revenue / Spent) incl. DA Forecast",
            formula: m => m.revenue_with_forecast / m.buy_report_spent,
            // If we interpret ROI as CPC/REV_PER_CLICK we can use one of the children metrics to calculate significance.
            // It's a hack but we can't do better than this.
            issStats: manualISSStats.buy_revenue_per_click,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_profit: {
            label: "Profit",
            explanation: "Profit (Revenue - Spent)",
            formula: m => m.revenue - m.buy_report_spent,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_profit_with_forecast: {
            label: "Profit with Forecast",
            explanation: "Profit (Revenue - Spent) incl. DA Forecast",
            formula: m => m.revenue_with_forecast - m.buy_report_spent,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_click_rpm: {
            label: "Click RPM",
            explanation: "Revenue per mille (thousand) clicks",
            formula: m => (m.revenue / m.buy_report_clicks) * 1000,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_click_rpm_with_forecast: {
            label: "Click RPM with Forecast",
            explanation: "Revenue with DA forecast per mille (thousand) clicks",
            formula: m => (m.revenue_with_forecast / m.buy_report_clicks) * 1000,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_click_revenue: {
            label: "Click Revenue",
            explanation: "Revenue per acquired Click",
            formula: m => m.revenue / m.buy_report_clicks,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_click_revenue_with_forecast: {
            label: "Click Revenue with Forecast",
            explanation: "Revenue with DA forecast per acquired Click",
            formula: m => m.revenue_with_forecast / m.buy_report_clicks,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_revenue_per_click,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_cps: {
            label: "CPS",
            explanation: "Cost per Session",
            formula: m => m.buy_report_spent / m.buy_report_sessions,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_spent_per_new_sessions,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        buy_report_cpai: {
            label: "CPMAI",
            explanation: "Cost per Mille (thousand) Ad Impressions",
            formula: m => (m.buy_report_spent / m.impressions) * 1000,
            // We receive aggregated data of spent & clicks.
            // We use the std of averages to approximate the real std.
            issStats: manualISSStats.buy_spent_per_impressions,
            calculationType: CalculationType.Mean,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        buy_report_new_sessions: {
            label: "New Sessions",
            explanation: "New Sessions from Bought Clicks",
            formula: m => m.buy_report_sessions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        buy_report_ffi: {
            label: "Prebid FFI",
            explanation: "Sum of the first 5 highest bids from Prebid, per session",
            formula: m => m.first_five_indicator / m.session_starts / 1000 / 1000,
            issStats: manualISSStats.buy_ffi_per_session_starts,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
    })
    .withMetricTable({
        initialMetrics: [
            "buy_report_clicks",
            "buy_report_sessions",
            "buy_report_session_click_ratio",
            "buy_report_cpc",
            "buy_report_spent",
            "revenue",
            "buy_report_roi",
        ],
        setup: {
            tabMeta: {
                spent: {
                    name: "Spent",
                    explanation: "Spent related metrics and suggestions of relevant Yield metrics",
                },
                ...yieldTableConfig.setup.tabMeta,
            },
            columnNames: {
                // if needed, add the column names to the yield setup
                ...yieldTableConfig.setup.columnNames,
            },
            tableMapping: {
                spent: {
                    combined: [
                        "buy_report_session_click_ratio",
                        "buy_report_roi",
                        "buy_report_roi_with_forecast",
                        "buy_report_profit",
                        "buy_report_profit_with_forecast",
                        "buy_report_click_rpm",
                        "buy_report_click_rpm_with_forecast",
                        "buy_report_click_revenue",
                        "buy_report_click_revenue_with_forecast",
                        "buy_report_cps",
                        "buy_report_cpai",
                    ],
                    spent: ["buy_report_clicks", "buy_report_spent", "buy_report_cpc"],
                    yield: [
                        "buy_report_new_sessions",
                        "revenue",
                        "revenue_with_forecast",
                        "session_rpm",
                        "session_rpm_with_forecast",
                        "buy_report_ffi",
                    ],
                    other: ["ads_per_session"],
                },
                ...yieldTableConfig.setup.tableMapping,
            },
        },
    });
