import { PREBID_SOURCE_OPTIONS } from "../../shared";
import { type PublicDimensionConfig, RenderDimension } from "../../types";

export const YieldPrebidDimensions = {
    preBidWon: {
        label: "Won",
        row_type: "Number",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },

    // Demand

    bidder: {
        description: "The winning/rendered bidder.",

        label: "Winning Bidder",
        row_type: "String",
        render: RenderDimension.PlainString,
        isTombstone: true, // moved to winning_bidder
        uiGroup: "demand",
    },
    winning_bidder: {
        description: "The winning/rendered bidder.",
        label: "Winning Bidder",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },

    highest_bidder: {
        description: "The highest bidder from prebid for the given placement.",

        label: "Highest Bidder",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    dsp: {
        label: "DSP",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    buyer: {
        label: "Buyer",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    advertiserDomain: {
        label: "Advertiser Domain",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    mediaType: {
        label: "Media Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    bid: {
        label: "Bid Landscape",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    timeToRespond: {
        label: "Time to Respond",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    size: {
        label: "Size",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "implementation",
    },
    prebid_source: {
        label: "Prebid Source",

        row_type: "Enum",
        render: RenderDimension.PlainString,
        enumOptions: PREBID_SOURCE_OPTIONS,
        uiGroup: "implementation",
    },
    brand: {
        label: "Brand",
        shouldLimit: true,
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "demand",
    },
    ivt_category: {
        label: "IVT Category",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    ivt_type: {
        label: "IVT Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    cookieDeprecationLabel: {
        label: "Cookie Deprecation",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "user",
    },
    timeZone: {
        description: "User time zone.",
        label: "Time Zone",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "time_based",
    },
    floor_prebid: {
        label: "Floor Prebid",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_prebid_raw: {
        label: "Floor Prebid Raw",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_amazon: {
        label: "Floor Amazon",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_gpt: {
        label: "Floor GPT",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_group: {
        label: "Floor Group",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
    },

    floor_threshold_raw: {
        label: "Floor Threshold Raw",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_is_v5: {
        label: "Is Floor V5",
        row_type: "Number",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_threshold: {
        label: "Floor Threshold",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_pbo: {
        label: "Floor PBO",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_pbo_raw: {
        label: "Floor PBO Raw",
        row_type: "String",
        render: RenderDimension.PlainString,
        hideFromApiDocs: true,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    floor_status: {
        label: "Floor Status",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    request_reduction_client_status: {
        label: "Bid Req. Optimization Status",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "content",
    },
} satisfies Record<string, Omit<PublicDimensionConfig, "id">>;
