import type { FC } from "react";
import { StatuspageProvider } from "ay-md-docs-render/src/statuspageContext";
import { graphql, PageProps } from "gatsby";
import { BuilderComponent } from "@builder.io/react";
import Layout from "components/Layout";
import Seo from "components/seo";

function PageTemplate({ data }: PageProps<any>) {
    const models = data?.allBuilderModels;
    const page = models.onePage?.content;

    return (
        <StatuspageProvider isDevMode={false}>
            <Layout bg="dark">
                {/* @ts-ignore */}
                <BuilderComponent name="page" content={page} />
            </Layout>
        </StatuspageProvider>
    );
}

export const Head: FC<PageProps<any>> = ({ data, location }) => {
    type TBreadcrumb = {
        name: string;
        url: string;
    };

    type TListItem = {
        "@type": string;
        position: number;
        name: string;
        item?: {
            "@id": string;
            "@type": string;
        };
    };

    const models = data?.allBuilderModels;
    const page = models.onePage?.content;

    const generateBreadcrumbTrail = () => {
        const breadcrumbs: TBreadcrumb[] = [];

        if (location.pathname !== "/") {
            breadcrumbs.push({
                name: "Assertive Yield",
                url: "/",
            });
        }

        let current = page;
        while (current) {
            breadcrumbs.push({
                name: current.data.title,
                url: current.data.url,
            });
            current = current.parent;
        }

        const itemListElement = breadcrumbs.map((item, index) => {
            const listItem: TListItem = {
                "@type": "ListItem",
                position: index + 1,
                name: item.name,
            };

            if (index !== breadcrumbs.length - 1) {
                listItem.item = {
                    "@id": item.url,
                    "@type": "Thing",
                };
            }
            return listItem;
        });

        return {
            "@type": "BreadcrumbList",
            "@id": `${location.pathname}#breadcrumb`,
            itemListElement,
        };
    };

    const firstPublished = new Date(page?.firstPublished).toISOString();
    const lastUpdated = new Date(page?.lastUpdated).toISOString();
    const pageData = page?.data ?? {};

    return (
        <Seo
            title={pageData.title}
            description={pageData.description}
            canonical={location.pathname}
            titleSuffixed={false}
        >
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "${location.pathname}",
            "url": "${location.pathname}",
            "name": "${pageData.title}",
            "datePublished": "${firstPublished}",
            "dateModified": "${lastUpdated}",
            "description": "${pageData.description}",
            "inLanguage": "en-US",
            "isPartOf": {
              "@type": "WebSite",
              "@id": "https://www.assertiveyield.com/#website",
              "url": "https://www.assertiveyield.com/",
              "name": "Assertive Yield",
              "description": "Investment Management Solutions",
              "inLanguage": "en-US",
            "publisher": {
              "@type": "Organization",
              "@id": "https://www.assertiveyield.com/#organization",
              "name": "Assertive Yield",
              "url": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield/"
              ],
              "logo": {
                "@type": "ImageObject",
                "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                "inLanguage": "en-US",
                "url": "https://assertiveyield.com/img/og-image.png",
                "contentUrl": "https://assertiveyield.com/img/og-image.png",
                "width": 479,
                "height": 189,
                "caption": "Assertive Yield"
              },
              "image": {
                "@type": "ImageObject",
                "@id": "https://res.cloudinary.com/dddoo7efp/image/upload/v1701326966/AY_b_p_V_black_3x_py5k4y.png",
                "inLanguage": "en-US",
                "URL": "https://res.cloudinary.com/dddoo7efp/image/upload/v1701326966/AY_b_p_V_black_3x_py5k4y.png",
                "contentUrl": "https://res.cloudinary.com/dddoo7efp/image/upload/v1701326966/AY_b_p_V_black_3x_py5k4y.png",
                "width": 300,
                "height": 141,
                "caption": "Assertive Yield"
              },
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
                },
                "query-input": {
                  "@type": "PropertyValueSpecification",
                  "valueRequired": "http://schema.org/True",
                  "valueName": "search_term_string"
                }
              }
            }
            },
            "about": {
              "@type": "Organization",
              "@id": "https://www.assertiveyield.com/#organization",
              "name": "Assertive Yield",
              "url": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield/"
              ],
              "logo": {
                "@type": "ImageObject",
                "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                "inLanguage": "en-US",
                "url": "https://assertiveyield.com/img/og-image.png",
                "contentUrl": "https://assertiveyield.com/img/og-image.png",
                "width": 79,
                "height": 189,
                "caption": "Assertive Yield"
              }
            },
          "breadcrumb": ${JSON.stringify(generateBreadcrumbTrail())},
          "potentialAction": {
            "@type": "ReadAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
            }
          }
        }
        `}
            </script>
        </Seo>
    );
};

export default PageTemplate;
export const pageQuery = graphql`
    query ($path: String!) {
        allBuilderModels {
            onePage(target: { urlPath: $path }, options: { cachebust: true }) {
                content
            }
        }
    }
`;
