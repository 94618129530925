export const VitalsCalculatedMetrics = [
    "vitals_lcp",
    "vitals_lcp_good_rate",
    "vitals_lcp_medium_rate",
    "vitals_lcp_bad_rate",
    "vitals_fid",
    "vitals_fid_good_rate",
    "vitals_fid_medium_rate",
    "vitals_fid_bad_rate",
    "vitals_cls",
    "vitals_cls_good_rate",
    "vitals_cls_medium_rate",
    "vitals_cls_bad_rate",
    "vitals_inp",
    "vitals_inp_good_rate",
    "vitals_inp_medium_rate",
    "vitals_inp_bad_rate",
    "vitals_ttfb",
    "vitals_ttfb_good_rate",
    "vitals_ttfb_medium_rate",
    "vitals_ttfb_bad_rate",
    "vitals_fcp",
    "vitals_fcp_good_rate",
    "vitals_fcp_medium_rate",
    "vitals_fcp_bad_rate",
] as const;
export type VitalsCalculatedMetrics = (typeof VitalsCalculatedMetrics)[number];

export const PREBID_SOURCE_OPTIONS = [
    "unknown",
    "client",
    "server",
    "server-appnexuspsp",
    "server-rubicon",
    "server-openwrap",
    "server-ay",
] as const;

export const REVENUE_SOURCE_OPTIONS = [
    "prebid",
    "dynamicAllocation",
    "lineitem",
    "video",
    "nativo",
    "taboola",
    "outbrain",
    "yahooGemini",
    "adSense",
    "aps",
    "ayMediation_cpa",
] as const;
