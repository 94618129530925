import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    traffic_shaping_server_flooring_samples: {},
    traffic_shaping_server_flooring_bids: {},
    traffic_shaping_server_flooring_wins: {},
    traffic_shaping_server_flooring_revenue: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawTrafficShapingServerFlooringMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "traffic_shaping_server_flooring_samples",
    "traffic_shaping_server_flooring_bids",
    "traffic_shaping_server_flooring_wins",
    "traffic_shaping_server_flooring_revenue",
    "traffic_shaping_server_flooring_request_rpmm",
] as const;
export type TrafficShapingServerFlooringMetrics = (typeof CALC_IDS)[number];
const DIMENSIONS = {
    traffic_shaping_server_flooring_datacenter: {
        label: "Data Center",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_bidder_id: {
        label: "Bidder Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_node: {
        label: "Node",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_version: {
        label: "Version",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_commit: {
        label: "Commit",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_meta_strategy: {
        label: "Meta Strategy",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_strategy: {
        label: "Strategy",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_os: {
        label: "OS",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_country: {
        label: "Country",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    traffic_shaping_server_flooring_hour: {
        label: "Hour (UTC)",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_format: {
        label: "Format",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    traffic_shaping_server_flooring_platform: {
        label: "Platform",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type TrafficShapingServerFlooringDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities(["by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        traffic_shaping_server_flooring_revenue: {
            label: "Revenue",
            explanation: "Revenue",
            formula: m => m.traffic_shaping_server_flooring_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_samples: {
            label: "Samples",
            explanation: "Samples",
            formula: m => m.traffic_shaping_server_flooring_samples,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_bids: {
            label: "Bids",
            explanation: "Bids",
            formula: m => m.traffic_shaping_server_flooring_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_wins: {
            label: "Wins",
            explanation: "Wins",
            formula: m => m.traffic_shaping_server_flooring_wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_request_rpmm: {
            label: "Request RPMM",
            explanation: "Revenue / Samples * 1000 * 1000",
            formula: m =>
                (m.traffic_shaping_server_flooring_revenue / m.traffic_shaping_server_flooring_samples) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
    })
    .withMetricTable({
        initialMetrics: [
            "traffic_shaping_server_flooring_revenue",
            "traffic_shaping_server_flooring_samples",
            "traffic_shaping_server_flooring_request_rpmm",
        ],
        setup: {
            columnNames: {
                global: "Global",
            },
            tabMeta: {
                overall: { name: "Overall", explanation: "Overall metrics" },
            },
            tableMapping: {
                overall: {
                    global: [...CALC_IDS],
                },
            },
        },
    })
    .withDimensions(DIMENSIONS);
