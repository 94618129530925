import { type PublicDimensionConfig, RenderDimension } from "../types";
import buyBase from "./shared/buy";

export const BUY_CAMPAIGN_DIMENSIONS = {
    buy_report_platform: {
        label: "Platform",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    buy_report_campaign_name: {
        label: "Campaign Name",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    buy_report_subAccountId: {
        label: "DSP Sub-Account Id",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    buy_report_subAccountName: {
        label: "DSP Sub-Account",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    buy_report_campaign_id: {
        label: "Campaign ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type BuyCampaignDimensionsIds = keyof typeof BUY_CAMPAIGN_DIMENSIONS;

export default buyBase.withDimensions(BUY_CAMPAIGN_DIMENSIONS);
