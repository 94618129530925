import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig, RenderDimension } from "../types";

const RAW_METRICS = {
    request_reduction_server_samples_wins: {},
    request_reduction_server_samples_bids: {},
    request_reduction_server_samples_predicts: {},
    request_reduction_server_samples_bid_true_positive: {},
    request_reduction_server_samples_bid_true_negative: {},
    request_reduction_server_samples_bid_false_positive: {},
    request_reduction_server_samples_bid_false_negative: {},
    request_reduction_server_samples_win_true_positive: {},
    request_reduction_server_samples_win_true_negative: {},
    request_reduction_server_samples_win_false_positive: {},
    request_reduction_server_samples_win_false_negative: {},
    request_reduction_server_samples_missed_revenue: {},
    request_reduction_server_samples_retained_revenue: {},
    request_reduction_server_samples_requests: {},
    request_reduction_server_samples_retained_requests: {},
    request_reduction_server_samples_win_revenue: {},
    request_reduction_server_samples_bid_revenue: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawRequestReductionServerSamplesMetrics = keyof typeof RAW_METRICS;

export const RequestReductionServerSamplesProfileMetricsIds = [
    "request_reduction_server_samples_requests",
    "request_reduction_server_samples_win_rate",
    "request_reduction_server_samples_bid_rate",
    "request_reduction_server_samples_wins",
    "request_reduction_server_samples_bids",
    "request_reduction_server_samples_win_revenue",
    "request_reduction_server_samples_bid_revenue",
    "request_reduction_server_samples_win_cpm",
    "request_reduction_server_samples_bid_cpm",
    "request_reduction_server_samples_request_rpmm",
] as const;

export type RequestReductionServerSamplesProfileMetricsType =
    (typeof RequestReductionServerSamplesProfileMetricsIds)[number];

export const RequestReductionServerSamplesProfile = {
    request_reduction_server_samples_bidder: {
        label: "Bidder",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_floor_meta_strategy: {
        label: "Meta Strategy",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    request_reduction_server_samples_floor_strategy: {
        label: "Strategy",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        limitedToAdminOrDev: true,
    },
    request_reduction_server_samples_datacenter: {
        label: "Data Center",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_bidder_mapped: {
        label: "Bidder (mapped)",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_country: {
        label: "Country",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_publisher_id: {
        label: "Publisher ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_placement_id: {
        label: "Placement ID",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_request_type: {
        label: "Request Type",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_format: {
        label: "Format",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_sizes: {
        label: "Sizes",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_platform: {
        label: "Platform",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_bundle: {
        label: "Bundle",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_os: {
        label: "Operating System",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_browser: {
        label: "Browser",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_device: {
        label: "Device",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_codebase_version: {
        label: "ML Codebase Version",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_version: {
        label: "Version",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_device_language: {
        label: "Device Language",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_utc_offset: {
        label: "UTC Offset",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_gdpr: {
        label: "GDPR",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_us_privacy: {
        label: "US Privacy",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_video_startdelay: {
        label: "Video Start Delay",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_video_skip: {
        label: "Video Skip",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_video_rewarded: {
        label: "Video Rewarded",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_url: {
        label: "URL",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_domain: {
        label: "Domain",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_custom1: {
        label: "Custom 1",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_custom2: {
        label: "Custom 2",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_custom3: {
        label: "Custom 3",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_regression_saveAt: {
        label: "Regression ML Saved At",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: false,
        limitedToAdminOrDev: true,
    },
    request_reduction_server_samples_regression_buildCommit: {
        label: "Regression ML Build Commit",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: false,
        limitedToAdminOrDev: true,
    },
    request_reduction_server_samples_regression_buildVersion: {
        label: "Regression ML Build Version",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: false,
        limitedToAdminOrDev: true,
    },
    request_reduction_server_samples_regression_commitDate: {
        label: "Regression ML Commit Date",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: false,
        limitedToAdminOrDev: true,
    },
    request_reduction_server_samples_video_playback_method: {
        label: "Video Playback Method",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_site_cat: {
        label: "Size Category",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
        shouldLimit: true,
    },
    request_reduction_server_samples_unit_types: {
        label: "Unit Types",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_page_cat: {
        label: "Page Category",
        row_type: "String",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_gps: {
        label: "Has GPS",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_omsdk: {
        label: "Has OMSDK",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_age: {
        label: "Has Age",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_gender: {
        label: "Has Gender",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_mraid: {
        label: "Has MRaid",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_vast: {
        label: "Has Vast",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_vpaid: {
        label: "Has VPaid",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_has_consent: {
        label: "Has Consent",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
    request_reduction_server_samples_is_qps: {
        label: "Is QPS",
        row_type: "Number",
        render: RenderDimension.PlainString,
        uiGroup: "none",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type RequestReductionServerSamplesProfileDimensionsIds = keyof typeof RequestReductionServerSamplesProfile;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities(["by_minute", "by_10_minutes", "by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(RequestReductionServerSamplesProfileMetricsIds)
    .withCalculatedMetrics({
        request_reduction_server_samples_bids: {
            label: "Bids",
            explanation: "Bids",
            formula: m => m.request_reduction_server_samples_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_bid_revenue: {
            label: "Bid revenue",
            explanation: "Bid revenue",
            formula: m => m.request_reduction_server_samples_bid_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_bid_rate: {
            label: "Bid rate",
            explanation: "Bid Responses from Bidder / Bid Requests to Bidder",
            formula: m => m.request_reduction_server_samples_bids / m.request_reduction_server_samples_requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 4,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_bid_cpm: {
            label: "Bid CPM",
            explanation: "Avg. CPM of Bid Responses from Bidder",
            formula: m =>
                (m.request_reduction_server_samples_bid_revenue / m.request_reduction_server_samples_bids) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },

        request_reduction_server_samples_wins: {
            label: "Wins",
            explanation: "Wins",
            formula: m => m.request_reduction_server_samples_wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_win_revenue: {
            label: "Win revenue",
            explanation: "Win revenue",
            formula: m => m.request_reduction_server_samples_win_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_win_rate: {
            label: "Win rate",
            explanation: "Impression Events from Bidder / Bid Requests to Bidder",
            formula: m => m.request_reduction_server_samples_wins / m.request_reduction_server_samples_requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 4,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_win_cpm: {
            label: "Win CPM",
            explanation: "Avg. CPM of Impression Events from Bidder (Revenue)",
            formula: m =>
                (m.request_reduction_server_samples_win_revenue / m.request_reduction_server_samples_wins) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_requests: {
            label: "Requests",
            explanation: "Total requests",
            formula: m => m.request_reduction_server_samples_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_request_rpmm: {
            label: "Request RPMM",
            explanation: "Revenue / Samples * 1000 * 1000",
            formula: m =>
                (m.request_reduction_server_samples_win_revenue / m.request_reduction_server_samples_requests) *
                1000 *
                1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
    })
    .withMetricTable({
        initialMetrics: [
            "request_reduction_server_samples_requests",
            "request_reduction_server_samples_win_rate",
            "request_reduction_server_samples_bid_rate",
            "request_reduction_server_samples_win_cpm",
            "request_reduction_server_samples_bid_cpm",
        ],
        setup: {
            tabMeta: {
                overall: { name: "Overall", explanation: "Sample report metrics" },
            },
            columnNames: {
                profile: "Profile",
                predictions: "Predictions",
            },
            tableMapping: {
                overall: {
                    profile: [
                        "request_reduction_server_samples_requests",
                        "request_reduction_server_samples_win_rate",
                        "request_reduction_server_samples_bid_rate",
                        "request_reduction_server_samples_wins",
                        "request_reduction_server_samples_bids",
                        "request_reduction_server_samples_win_revenue",
                        "request_reduction_server_samples_bid_revenue",
                        "request_reduction_server_samples_win_cpm",
                        "request_reduction_server_samples_bid_cpm",
                        "request_reduction_server_samples_request_rpmm",
                    ],
                },
            },
        },
    })
    .withDimensions(RequestReductionServerSamplesProfile);
